import gql from 'graphql-tag'

export const CreateNote = gql`
  mutation CreateNote($data: NoteInput!) {
    createNote(data: $data) {
      _id
    }
  }
`

export const UpdateNote = gql`
  mutation UpdateNote($id: MongoID!, $data: NoteInput!) {
    updateNote(id: $id, data: $data) {
      _id
    }
  }
`

export const DeleteNote = gql`
  mutation DeleteNote($id: MongoID!) {
    deleteNote(id: $id) {
      _id
    }
  }
`
