import React, { useState } from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { Grid } from 'semantic-ui-react'
import { useLocation, useHistory } from 'react-router'
import { useIntl } from 'react-intl'
import {
  Button,
  Form,
  ErrorModal,
  SubmitButton,
} from '@labsavvyapp/ui-components'

import style from './NewPasswordPage.module.css'
import sharedStyle from '../../../styles/shared.module.css'
import MainContainer from '../../../components/MainContainer/MainContainer'
import PageHeader from '../../../components/PageHeader/PageHeader'
import { PROFILE } from '../../../config/routes'
import PasswordField from './PasswordField/PasswordField'
import { GetUserEmails } from '../../../graphql/user/queries.js'
import { RecoverEmail } from '../../../graphql/user/mutations.js'

export default function NewPasswordPage() {
  const { formatMessage } = useIntl()
  const { push } = useHistory()
  const { data: getMeData } = useQuery(GetUserEmails)
  const [changePassword] = useMutation(RecoverEmail)
  const { search } = useLocation()
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)

  const INITIAL_FORM_DATA = {
    password: '',
    newPassword: '',
  }

  function getQueryString(variable) {
    const queryParams = new URLSearchParams(search)
    return queryParams.get(variable)
  }

  async function handleSubmit({ newPassword }) {
    try {
      await changePassword({
        variables: {
          proof: getQueryString('proof'),
          email: getMeData.getMe.emails[0].address,
          newPassword,
        },
      })
      push(`${PROFILE.base}/${PROFILE.passwordUpdated}`)
    } catch (error) {
      setErrorMessage(error.graphQLErrors[0].message)
      setShowErrorModal(true)
    }
  }

  return (
    <>
      {/* Modals */}
      <ErrorModal
        message={errorMessage}
        onCloseClick={() => setShowErrorModal(false)}
        open={showErrorModal}
      />

      <MainContainer>
        <PageHeader title="Choose a New Password" />

        <Form
          intl={{
            formatMessage,
          }}
          className={style.form}
          initialFormData={INITIAL_FORM_DATA}
          onSubmit={handleSubmit}
        >
          <Grid>
            <Grid.Row>
              <Grid.Column tablet={16} computer={6}>
                <div className={style.passwordStrengthNote}>
                  Your new password must contain at least 8 letters, with 1
                  uppercase, 1 lower case, and a number or symbol.
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className={sharedStyle.marginTop30}>
              <Grid.Column tablet={16} computer={6}>
                <PasswordField
                  name="password"
                  placeholder="Choose a password"
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column tablet={16} computer={6}>
                <PasswordField
                  name="newPassword"
                  placeholder="Enter password again"
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column tablet={16} computer={6}>
                <div className={sharedStyle.marginTop30}>
                  <SubmitButton className={sharedStyle.marginRight10}>
                    Continue
                  </SubmitButton>
                  <Button
                    variant="secondary"
                    onClick={() => push(PROFILE.base)}
                  >
                    Cancel
                  </Button>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </MainContainer>
    </>
  )
}
