import React from 'react'
import { Loader } from 'semantic-ui-react'
import { useParams, generatePath } from 'react-router'
import { List, Card } from '@labsavvyapp/ui-components'
import { isEmpty } from '@labsavvyapp/ui-components/lib/utils'

import ButtonPlus from '../../../components/Buttons/ButtonPlus'
import ClientNote from './ClientNote'
import { CLIENTS } from '../../../config/routes'
import style from './ClientNotes.module.css'

export default function ClientNotes({ data = [], loading, consumer }) {
  const { clientId } = useParams()
  return (
    <div className={style.container}>
      <div className={style.header}>
        <h3>{consumer} Notes</h3>

        <ButtonPlus
          testId="button-add-note"
          to={generatePath(CLIENTS.newNote, { clientId })}
        />
      </div>

      <List className={style.reportList} data-test="client-notes-list">
        {loading && <Loader active inline="centered" />}

        {!isEmpty(data) &&
          data.map((note) => (
            <ClientNote key={note.id} clientId={clientId} data={note} />
          ))}

        {!loading && isEmpty(data) && (
          <Card data-test="card-no-client-notes-found" emptyCard shadowed>
            No {consumer} notes found.
          </Card>
        )}
      </List>
    </div>
  )
}
