import gql from 'graphql-tag'

export const ListProviders = gql`
  query ListProviders {
    listProviders(limit: 20) {
      providers {
        _id
        name
      }
    }
  }
`

export const GetProvider = gql`
  query GetProvider($id: MongoID!) {
    getProvider(id: $id) {
      name
    }
  }
`
