import React from 'react'
import { useHistory } from 'react-router'
import { Button } from '@labsavvyapp/ui-components'

import { PlusIcon } from '../Icons/light'
import style from './ButtonPlus.module.css'

/**
 * ButtonPlus component.
 *
 * Link button with the 'plus' icon.
 */
export default function ButtonPlus({ to, testId, className }) {
  const { push } = useHistory()

  return (
    <Button
      className={style.plusButton || className}
      data-test={testId}
      onClick={() => push(to)}
    >
      <PlusIcon />
    </Button>
  )
}
