import React from 'react'
import { NavLink, useParams, useHistory, generatePath } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import { camelizeKeys } from 'humps'
import { Button, Loader } from '@labsavvyapp/ui-components'

import ReportDetails from '../../../components/ReportDetails/ReportDetails'
import { DetailsLayout } from '../../../layouts/DetailsLayout/DetailsLayout'
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs'
import Panel, { PanelHeader, PanelBody } from '../../../components/Panel/Panel'
import { BackwardIcon } from '../../../components/Icons/light'
import ButtonPlus from '../../../components/Buttons/ButtonPlus'
import LabReportNotes from '../ViewEdit/LabReportNotes'
import { LAB_REPORTS } from '../../../config/routes'
import ExternalReferences from './ExternalReferences/ExternalReferences'
import FlagStatement from './FlagStatement'
import { ContainerSize } from '../../../components/Utils/ContainerSize'
import TestReferenceFAQ from './TestReferenceFAQ'
import TestReferenceAYC from './TestReferenceAYC'
import LabResultReferenceInfo from './LabResultReferenceInfo'
import LabResultNotes from './LabResultNotes'
import ReviewBoardApproved from './ReviewBoardApproved'
import {
  GetLabOrder,
  GetLabOrderHistoryByTestReference,
} from '../../../graphql/lab-orders/queries.js'
import HistoryChart from './HistoryChart/HistoryChart'
import PageError from '../../../components/PageError/PageError'
import style from './ResultDetails.module.css'

function getLabResult(id, results) {
  return results.reduce((result, item) => {
    let test = item.tests.find((test) => test.id === id)
    if (test !== undefined) {
      result = test
    }
    return result
  }, null)
}

export default function ResultDetails() {
  const { push } = useHistory()
  const { labReportId, resultId } = useParams()
  const labOrderURL = generatePath(LAB_REPORTS.view, {
    labReportId,
  })

  // Fetch Lab Order
  const { data, loading: labOrderLoading } = useQuery(GetLabOrder, {
    variables: { id: labReportId },
  })
  const formattedResponse = camelizeKeys(data)
  const result =
    formattedResponse &&
    getLabResult(resultId, formattedResponse.getLabOrder.results)
  const labOrder = formattedResponse && formattedResponse.getLabOrder

  // Fetch Lab Order History
  const { data: labOrderHistoryData } = useQuery(
    GetLabOrderHistoryByTestReference,
    {
      skip: !result || !result.reference.id,
      variables: {
        labOrderId: labReportId,
        testReferenceId: result && result.reference.id,
      },
    },
  )
  const labOrderHistoryDataResponse = camelizeKeys(labOrderHistoryData)
  const labOrderHistory =
    labOrderHistoryDataResponse &&
    labOrderHistoryDataResponse.getLabOrderHistoryByTestReference

  if (labOrderLoading) return <Loader active />

  if (!result)
    return (
      <PageError message="Lab report not found.">
        <Button variant="basic" onClick={() => push(labOrderURL)}>
          Back to Lab Order
        </Button>
      </PageError>
    )

  return (
    <div className={style.container}>
      <DetailsLayout>
        <DetailsLayout.Header>
          <DetailsLayout.Panel>
            <Breadcrumbs
              text="Back to Lab Order"
              onClick={() => push(labOrderURL)}
              testId="link-back-to-lab-order"
            />
          </DetailsLayout.Panel>
        </DetailsLayout.Header>

        <DetailsLayout.Panel className={style.leftPanel}>
          <ReportDetails labOrder={labOrder} />

          <section className={style.reportNotes}>
            <div className={style.notesHeader}>
              <h3 className={style.reportNotesTitle}>Report Notes</h3>

              <ButtonPlus
                to={generatePath(LAB_REPORTS.newNote, { labReportId })}
              />
            </div>

            <div>
              <LabReportNotes labReportId={labReportId} />
            </div>
          </section>
        </DetailsLayout.Panel>

        <DetailsLayout.Panel size={8}>
          <ContainerSize>
            {({ width }) => (
              <div>
                <Panel>
                  <PanelHeader
                    as={NavLink}
                    to={generatePath(LAB_REPORTS.view, { labReportId })}
                  >
                    <div className={style.backButtonContainer}>
                      <BackwardIcon className={style.backIcon} />
                      Back
                    </div>
                  </PanelHeader>

                  <PanelBody>
                    {/* Reference Info */}
                    <LabResultReferenceInfo result={result} />

                    {/* History Chart */}
                    {labOrderHistory && (
                      <HistoryChart
                        width={width}
                        data={labOrderHistory.units}
                        results={labOrderHistory.results}
                        ranges={labOrderHistory.ranges}
                      />
                    )}

                    {!!result.notes.length && (
                      <LabResultNotes notes={result.notes} />
                    )}

                    {/* Flag Statements */}
                    <FlagStatement result={result} />

                    {/* Ask Your Clinician */}
                    {result.status === 'abnormal' && (
                      <TestReferenceAYC
                        data={result.resultSummaries.whatShouldIAskMyClinician}
                      />
                    )}

                    {/* FAQ */}
                    <TestReferenceFAQ
                      resultSummaries={result.resultSummaries}
                      aka={result.aka}
                    />

                    {/* Review Board Approved stamp */}
                    <ReviewBoardApproved />

                    {/* External References */}
                    <ExternalReferences
                      externalReferences={result.externalReferences}
                      result={result}
                    />
                  </PanelBody>
                </Panel>
              </div>
            )}
          </ContainerSize>
        </DetailsLayout.Panel>
      </DetailsLayout>
    </div>
  )
}
