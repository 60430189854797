export function isValidDate(date) {
  if (isNaN(Date.parse(date))) {
    throw new Error(
      JSON.stringify({
        id: 'invalid_date',
        defaultMessage: 'Invalid date',
      }),
    )
  }
}

export function isFutureDate(date) {
  if (Date.parse(date) < Date.now()) {
    throw new Error(
      JSON.stringify({
        id: 'invalid_date',
        defaultMessage: 'Date should be in the future',
      }),
    )
  }
}

export function isPastDate(date) {
  if (Date.parse(date) > Date.now()) {
    throw new Error(
      JSON.stringify({
        id: 'invalid_date',
        defaultMessage: 'Date should be in the past',
      }),
    )
  }
}
