import React from 'react'
import { Icon } from 'semantic-ui-react'

import style from './CurrentACHPayment.module.css'

const CurrentACHPayment = ({ current, onReset }) => {
  return (
    <div className={style.container}>
      <div className={style.bankDetails}>
        <span>{current.bank_name}</span>
        <span>********* {current.last4}</span>
        <span>{current.routing_number}</span>
      </div>
      <div className={style.actions}>
        <Icon name="delete" className={style.reset} onClick={onReset} />
      </div>
    </div>
  )
}

export default CurrentACHPayment
