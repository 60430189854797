import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { useIntl } from 'react-intl'
import {
  Button,
  Form,
  Modal,
  SubmitButton,
  VerificationCodeField,
} from '@labsavvyapp/ui-components'

import MainContainer from '../../../components/MainContainer/MainContainer'
import { PROFILE } from '../../../config/routes'
import { GetUserEmails } from '../../../graphql/user/queries.js'
import {
  PreRecoverEmail,
  ProofRecoveryEmail,
} from '../../../graphql/user/mutations.js'
import style from './AccountVerificationPage.module.css'

const AccountVerificationPage = () => {
  const { formatMessage } = useIntl()

  const [modalSubmitCodeOpen, setModalSubmitCodeOpen] = useState(false)
  const [modalErrorCodeOpen, setModalErrorCodeOpen] = useState(false)
  const { push } = useHistory()

  const [preRecoverEmail] = useMutation(PreRecoverEmail)
  const [proofRecoveryEmail] = useMutation(ProofRecoveryEmail)
  const { data: profileData } = useQuery(GetUserEmails)

  const profileEmail = profileData && profileData.getMe.emails[0].address
  const digitsInputFormField = 'digits_input'
  const initialFormData = { [digitsInputFormField]: '' }

  useEffect(() => {
    profileEmail && preRecoverEmail({ variables: { email: profileEmail } })
  }, [profileEmail])

  useEffect(() => {
    modalSubmitCodeOpen &&
      profileEmail &&
      preRecoverEmail({ variables: { email: profileEmail } })
  }, [modalSubmitCodeOpen])

  const handleFormSubmit = async (formData) => {
    const { [digitsInputFormField]: token } = formData
    try {
      const response = await proofRecoveryEmail({
        variables: {
          email: profileEmail,
          token,
        },
      })
      const proofUuid = response.data.proofRecoveryEmail
      push(`${PROFILE.base}/${PROFILE.newPassword}?proof=${proofUuid}`)
    } catch (error) {
      setModalErrorCodeOpen(true)
    }
  }

  return (
    <>
      <Modal
        onCloseClick={() => setModalSubmitCodeOpen(false)}
        open={modalSubmitCodeOpen}
      >
        The 6-digit code has been submitted. Please check your email.
      </Modal>

      <Modal
        onCloseClick={() => setModalErrorCodeOpen(false)}
        open={modalErrorCodeOpen}
      >
        The 6-digit code is invalid.
      </Modal>

      <MainContainer>
        <header className={style.header}>
          <h1 className="page-title">Reset Password: Account Verification</h1>
        </header>

        <Form
          intl={{
            formatMessage,
          }}
          className={style.contentContainer}
          onSubmit={handleFormSubmit}
          initialFormData={initialFormData}
        >
          <div className={style.actionText}>
            Check your email for a 6-digit code to verify your account.
          </div>
          <VerificationCodeField
            name={digitsInputFormField}
            className={style.verificationCodeInput}
            testId="verification-code-input"
          />
          <div className={style.buttonsContainer}>
            <SubmitButton size="small" data-test="submit-button">
              CONTINUE
            </SubmitButton>
            <Button
              className={style.cancelButton}
              onClick={() => push(PROFILE.base)}
              size="small"
              variant="secondary"
              data-test="button-cancel"
            >
              CANCEL
            </Button>
          </div>
          <div
            className={style.resendCode}
            onClick={() => setModalSubmitCodeOpen(true)}
            data-test="link-resend-code"
          >
            RESEND CODE
          </div>
        </Form>
      </MainContainer>
    </>
  )
}

export default AccountVerificationPage
