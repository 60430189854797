import React from 'react'
import get from 'lodash/get'
import { format } from 'date-fns'
import classnames from 'classnames'
import { formatPhoneNumber } from 'react-phone-number-input'
import { NameInitials } from '@labsavvyapp/ui-components'

import getAge from '../../../utils/get-age'

import style from './style.module.css'

export default function ContactProfile({ contact, getProfileUrl }) {
  if (!contact) return <section className={style.container}></section>

  const phones =
    contact.phones &&
    contact.phones.map(({ number, type }, index) => (
      <span className={style.info} key={`phone-${contact.shortId}-${index}`}>
        {formatPhoneNumber(`+1${number}`)} {type && `(${type})`}
      </span>
    ))

  const emails =
    contact.emails &&
    contact.emails.map(({ address }, index) => (
      <span className={style.info} key={`emails-${contact.shortId}-${index}`}>
        {address}
      </span>
    ))

  return (
    <section className={style.container}>
      <h1 className={style.name}>{contact.name.display}</h1>
      <span className={style.shortId}>Client ID: {contact.shortId}</span>

      {contact.profilePhoto ? (
        <img
          className={style.image}
          src={contact.profilePhoto.url}
          alt="Client"
        />
      ) : (
        <NameInitials
          firstName={contact.name.first}
          lastName={contact.name.last}
          size={110}
        />
      )}

      <span className={classnames(style.info, style.gender)}>
        {contact.gender}
      </span>
      <span className={style.info}>
        {format(new Date(contact.dob), 'MMMM d, yyyy')} / Age{' '}
        {getAge(contact.dob)}
      </span>
      <span className={style.info}>{get(contact, 'address.street')}</span>
      <span className={style.info}>
        {
          (get(contact, 'address.city'),
          get(contact, 'address.state'),
          get(contact, 'address.zip'))
        }
      </span>
      {phones}
      {emails}

      <a className={style.link} href={getProfileUrl(contact)} target="_blank">
        View Full Profile
      </a>
    </section>
  )
}
