import React, { useState } from 'react'
import { Grid } from 'semantic-ui-react'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'
import { useHistory, useLocation } from 'react-router-dom'
import { useIntl } from 'react-intl'

import {
  Form,
  InputField,
  SubmitButton,
  validate,
} from '@labsavvyapp/ui-components'

import style from './RecoverPassword.module.css'
import logo from '../../../assets/labsavvy-logo.svg'
import { SIGN } from '../../../config/routes'

const RECOVER_EMAIL = gql`
  mutation recoverEmail(
    $proof: String!
    $email: EmailAddress!
    $newPassword: String!
  ) {
    recoverEmail(proof: $proof, email: $email, newPassword: $newPassword)
  }
`

/**
 * Recover password. Step 3: Set new password.
 */
const RecoverNewPassword = () => {
  const { formatMessage } = useIntl()
  const { push } = useHistory()

  const [recoverEmail] = useMutation(RECOVER_EMAIL)
  const { search } = useLocation()
  const [formErrors, setFormErrors] = useState({})
  const INITIAL_FORM_DATA = {
    newPassword: '',
    newPasswordConfirmation: '',
  }

  /**
   * Checks if the strings are equal.
   */
  const isEqual = (newPassword, newPasswordConfirmation) => {
    return newPassword === newPasswordConfirmation
  }

  /**
   * Form submission.
   */
  // FIXME: Fix how server errors are handled (https://labsavvy.atlassian.net/browse/LS-511)
  const onSubmit = async ({ newPassword, newPasswordConfirmation }) => {
    const queryParams = new URLSearchParams(search)
    const email = queryParams.get('email')
    const proof = queryParams.get('proof')

    if (!isEqual(newPassword, newPasswordConfirmation)) {
      const serverError = new Error(
        JSON.stringify({
          id: 'server_error',
          defaultMessage: "Passwords doesn't match",
        }),
      )
      setFormErrors({
        newPasswordConfirmation: serverError,
      })
      return
    }

    try {
      await recoverEmail({
        variables: {
          proof,
          email,
          newPassword,
        },
      })

      push(SIGN.recoverFinish)
    } catch (error) {
      const serverError = new Error(
        JSON.stringify({
          id: 'server_error',
          defaultMessage: error.graphQLErrors[0].message,
        }),
      )
      setFormErrors({
        newPasswordConfirmation: serverError,
      })
    }
  }

  return (
    <Form
      intl={{
        formatMessage,
      }}
      className={style.root}
      errors={formErrors}
      initialFormData={INITIAL_FORM_DATA}
      onSubmit={onSubmit}
    >
      <Grid textAlign="center" verticalAlign="middle">
        <Grid.Column>
          <div className={style.head}>
            <img alt="logo" src={logo} className={style.logo} />
          </div>
          <h3 className={style.title}>Reset password</h3>

          <p className={style.text}>Please enter the new password.</p>

          <InputField
            className={style.inputField}
            name="newPassword"
            type="password"
            placeholder="New password"
            validate={validate.combine(validate.notEmpty())}
          />
          <InputField
            className={style.inputField}
            name="newPasswordConfirmation"
            type="password"
            placeholder="Confirm new password"
            validate={validate.combine(validate.notEmpty())}
          />
          <div className={style.actions}>
            <SubmitButton>Change password</SubmitButton>
          </div>
        </Grid.Column>
      </Grid>
    </Form>
  )
}

export default RecoverNewPassword
