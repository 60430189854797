export const SORT_OPTIONS = Object.freeze({
  defaultSortOption: 'nameAZ',
  options: [
    {
      text: 'Name A-Z',
      queryStringValue: 'nameAZ',
      key: 'name',
      value: 'asc',
    },
    {
      text: 'Name Z-A',
      queryStringValue: 'nameZA',
      key: 'name',
      value: 'desc',
    },
  ],
})
