import React from 'react'

import { SanitizedHTML } from '@labsavvyapp/ui-components'
import capitalize from '../../../utils/capitalize'
import style from './FlagStatement.module.css'

const ABNORMAL = 'abnormal'

export default function FlagStatement({ result }) {
  const currentYear = new Date().getFullYear()
  const flagStatement = result.flagStatement
  if (result.status === ABNORMAL) {
    return (
      (flagStatement || result.indicator) && (
        <section className={style.container}>
          <div>
            <b>
              {capitalize(result.indicator).replace(/-/g, ' ')}
              {flagStatement && ': '}
            </b>{' '}
            {flagStatement && <SanitizedHTML text={flagStatement} />}
            <footer className={style.footer}>
              FlagStatement™ by LabSavvy © {currentYear}
            </footer>
          </div>
        </section>
      )
    )
  }

  return null
}
