import gql from 'graphql-tag'

export const CardDetailsFragment = gql`
  fragment CardDetails on CardPaymentMethod {
    id
    object
    exp_month
    exp_year
    last4
  }
`

export const BankDetailsFragment = gql`
  fragment BankDetails on BankPaymentMethod {
    id
    object
    routing_number
    last4
    bank_name
  }
`
