import React from 'react'
import { Segment } from 'semantic-ui-react'
import { useHistory, useParams, generatePath } from 'react-router-dom'
import { format } from 'date-fns'
import get from 'lodash/get'
import { camelizeKeys } from 'humps'
import {
  Button,
  Form,
  RTEField,
  SubmitButton,
  validate,
  useSavingModals,
  InputField,
} from '@labsavvyapp/ui-components'
import { useMutation, useQuery } from '@apollo/react-hooks'
import delay from 'await-delay'
import { useIntl } from 'react-intl'

import capitalize from '../../utils/capitalize'
import { LAB_REPORTS, CLIENTS } from '../../config/routes'
import ReportDetails from '../../components/ReportDetails/ReportDetails'
import ClientDetails from '../Clients/components/ClientDetails/ClientDetails'
import {
  CreateNote,
  UpdateNote,
  DeleteNote,
} from '../../graphql/notes/mutations.js'
import { GetPatient } from '../../graphql/clients/queries.js'
import { GetNote, ListNotes } from '../../graphql/notes/queries.js'
import { GetMe } from '../../graphql/user/queries.js'
import style from './NotesFormPage.module.css'

export default function NoteFormPage() {
  const { formatMessage } = useIntl()
  const { push } = useHistory()
  const { noteId, labReportId, clientId } = useParams()

  // Fetch client data
  const { data: clientResponse, loading: clientLoading } = useQuery(
    GetPatient,
    {
      variables: { id: clientId },
      skip: !clientId,
    },
  )
  const clientData = camelizeKeys(clientResponse)

  // Fetch user information
  const { data: userData } = useQuery(GetMe)
  const consumerTitle = capitalize(
    get(userData, 'getMe.project.consumers_title', 'Client'),
  )
  // Page title
  let pageTitle
  if (noteId) {
    pageTitle = labReportId ? (
      <h1>Report Note</h1>
    ) : (
      <h1>{consumerTitle} Note</h1>
    )
  } else {
    pageTitle = labReportId ? (
      <h1>New Report Note</h1>
    ) : (
      <h1>New {consumerTitle} Note</h1>
    )
  }

  const getRedirectUrl = () => {
    if (clientId) {
      return generatePath(CLIENTS.view, { clientId })
    }

    return generatePath(LAB_REPORTS.view, { labReportId })
  }

  const [
    modals,
    {
      showSavingModal,
      showSavedModal,
      showErrorModal,
      showConfirmationModal: showDeleteConfirmationModal,
    },
  ] = useSavingModals({
    savingMessage: "We're saving your note, please wait...",
    savedMessage: 'Your note has been saved',
    confirmationMessage: 'Do you really want to delete this note?',
    onConfirm: () => deleteNote({ variables: { id: noteId } }),
    onSuccess: () => push(getRedirectUrl()),
  })

  /**
   * Error handling
   */
  const handleServerError = () => {
    showSavingModal(false)
    showErrorModal(true)
  }

  // Fetch Note
  const { data } = useQuery(GetNote, {
    skip: !noteId,
    variables: { id: noteId },
  })

  const getListNotesQueryVariables = () => {
    const variables = { filter: { lab_order_id: labReportId } }
    if (clientId) {
      variables.filter = {
        patient_id: clientId,
      }
    }
    return variables
  }

  const [createNote] = useMutation(CreateNote, {
    refetchQueries: [
      {
        query: ListNotes,
        variables: getListNotesQueryVariables(),
      },
    ],
  })
  const [updateNote] = useMutation(UpdateNote, {
    refetchQueries: [
      {
        query: ListNotes,
        variables: getListNotesQueryVariables(),
      },
    ],
  })
  const [deleteNote] = useMutation(DeleteNote, {
    refetchQueries: [
      {
        query: ListNotes,
        variables: getListNotesQueryVariables(),
      },
    ],
  })

  const decorateFormData = (formData) => {
    return {
      ...formData,
      patient_id: clientId,
      lab_order_id: labReportId,
    }
  }

  // eslint-disable-next-line no-unused-vars
  const parseFormInputData = ({ created_at, ...formData }) => {
    return {
      ...formData,
      created_at: format(new Date(data.getNote.created_at), 'MM / dd / yyyy'),
    }
  }

  const onAdd = async (formData) => {
    try {
      showSavingModal(true)

      await createNote({
        variables: {
          data: decorateFormData(formData),
        },
      })

      await delay(1000)
      showSavingModal(false)
      showSavedModal(true)
      await delay(1000)

      push(getRedirectUrl())
    } catch {
      handleServerError()
    }
  }

  const onEdit = async (formData) => {
    try {
      showSavingModal(true)
      await updateNote(
        decorateFormData({
          variables: {
            id: formData._id,
            data: {
              patient_id: formData.clientId,
              lab_order_id: formData.labReportId,
              body: formData.body,
            },
          },
        }),
      )
      await delay(1000)
      showSavingModal(false)
      showSavedModal(true)
      await delay(1000)

      push(getRedirectUrl())
    } catch (error) {
      handleServerError(error)
    }
  }

  return (
    <>
      <Form
        intl={{
          formatMessage,
        }}
        initialFormData={data && parseFormInputData(data.getNote)}
        className={style.container}
        onSubmit={noteId ? onEdit : onAdd}
      >
        <Segment className={style.formContainer}>
          <div className={style.header}>{pageTitle}</div>

          <div className={style.panels}>
            <div className={style.leftPanel}>
              {data && data.getNote.created_at && (
                <InputField
                  className={style.createdDate}
                  name="created_at"
                  label="Date"
                  disabled
                />
              )}

              {labReportId && <ReportDetails />}

              {clientId && (
                <ClientDetails
                  client={clientData && clientData.getPatient}
                  loading={clientLoading}
                />
              )}
            </div>

            <div className={style.rightPanel}>
              <RTEField
                className={style.noteInput}
                name="body"
                label="Note"
                validate={validate.notEmpty()}
              />
            </div>
          </div>
        </Segment>

        <div className={style.buttonsContainer}>
          {data && (
            <Button
              variant="basic"
              className={style.deleteButton}
              onClick={showDeleteConfirmationModal}
            >
              Delete Note
            </Button>
          )}

          <Button variant="basic" onClick={() => push(getRedirectUrl())}>
            Cancel
          </Button>

          <SubmitButton primary>Save</SubmitButton>
        </div>
      </Form>

      {/* Modals */}
      {modals}
    </>
  )
}
