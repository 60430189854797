import React from 'react'

import Panel, { PanelHeader, PanelBody } from '../../../components/Panel/Panel'
import style from './TestReferenceAYC.module.css'

export default function TestReferenceAYC({ data }) {
  if (!data.length) return null

  return (
    <Panel>
      <PanelHeader>
        <h2 className={style.title}>Ask your Clinician</h2>
      </PanelHeader>

      <PanelBody>
        <ul className={style.questions}>
          {data.map((question, index) => (
            <li key={`clinician-question-${index}`} className={style.question}>
              {question}
            </li>
          ))}
        </ul>
      </PanelBody>
    </Panel>
  )
}
