import React from 'react'
import { Switch, Route } from 'react-router'

import { LAB_REPORTS, NOTES } from '../config/routes'
import withMainLayout from '../layouts/Main/withMainLayout'
import LabReportListPage from '../pages/LabReports/List/LabReportListPage'
import NewLabOrderContainer from '../pages/LabReports/New/NewLabOrderContainer'
import ReviewLabOrder from '../pages/LabReports/Review/ReviewLabOrder'
import LabReportViewEditPage from '../pages/LabReports/ViewEdit/LabReportViewEditPage'
import ResultDetails from '../pages/LabReports/ResultDetails/ResultDetails'
import NotesRoutes from './NotesRoutes'

const LabReportsRoutes = ({ path, me, loggedIn }) => (
  <Route
    path={path}
    render={({ match }) => (
      <Switch>
        <Route
          exact
          path={match.path}
          render={() =>
            withMainLayout(LabReportListPage, {
              me,
              loggedIn,
            })
          }
        />
        <Route
          path={LAB_REPORTS.new}
          render={() =>
            withMainLayout(NewLabOrderContainer, {
              me,
              loggedIn,
            })
          }
        />
        <Route
          exact
          path={LAB_REPORTS.review}
          render={() =>
            withMainLayout(ReviewLabOrder, {
              me,
              loggedIn,
            })
          }
        />
        <Route
          exact
          path={LAB_REPORTS.view}
          render={() =>
            withMainLayout(LabReportViewEditPage, {
              me,
              loggedIn,
            })
          }
        />
        <Route
          exact
          path={LAB_REPORTS.resultDetails}
          render={() =>
            withMainLayout(ResultDetails, {
              me,
              loggedIn,
            })
          }
        />
        <NotesRoutes
          path={match.path}
          parentPath={`:labReportId${NOTES.base}`}
          me={me}
          loggedIn={loggedIn}
          fallbackRouteComponent={LabReportListPage}
        />
      </Switch>
    )}
  />
)

export default LabReportsRoutes
