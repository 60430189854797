import gql from 'graphql-tag'

export const ListPartners = gql`
  query ListPartners {
    listPartners(sort: { name: asc }, limit: 50) {
      partners {
        _id
        name
      }
    }
  }
`

export const GetPartner = gql`
  query GetPartner($id: MongoID!) {
    getPartner(id: $id) {
      name
    }
  }
`

export const ListMyPartners = gql`
  query ListMyPartners {
    listMyPartners(limit: 40) {
      partners {
        _id
        name
        projects {
          _id
          name
        }
      }
    }
  }
`
