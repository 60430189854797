/**
 * This strategy only accepts:
 * - Debit Card
 * - ACH Payment
 */
import React from 'react'
import { useMutation, useQuery } from '@apollo/react-hooks'
import get from 'lodash/get'
import { useSavingModals } from '@labsavvyapp/ui-components'

import style from './HowDoWePayYouTab.module.css'
import { UpdatePaymentWePayYou } from '../../../../graphql/ecommerce/mutations.js'
import { GetPaymentStrategies } from '../../../../graphql/ecommerce/queries.js'
import PaymentMethod, {
  CARD_TYPE,
  BANK_TYPE,
} from '../components/PaymentMethod/PaymentMethod'

const HowDoWePayYouTab = () => {
  const { data } = useQuery(GetPaymentStrategies)
  const wePayYouPaymentMethod = get(
    data,
    'ecommerce_getPaymentStrategies.we_pay_you',
    {},
  )
  const [setWePayYouPaymentMethod] = useMutation(UpdatePaymentWePayYou, {
    refetchQueries: [
      {
        query: GetPaymentStrategies,
      },
    ],
  })

  const [modals, { showModals }] = useSavingModals({
    savingMessage:
      "We're saving your How do we pay you? payment method, please wait...",
    savedMessage: 'Your payment method has been saved',
    callback: async ({ paymentMethod }) => {
      await setWePayYouPaymentMethod({
        variables: paymentMethod,
      })
    },
    onError: (error) => error,
  })

  return (
    <div className={style.container}>
      <h3>How do we pay you?</h3>
      <PaymentMethod
        availableMethods={[CARD_TYPE, BANK_TYPE]}
        paymentMethod={wePayYouPaymentMethod}
        handlePaymentMethod={async (paymentMethod) => {
          showModals({ paymentMethod })
        }}
      />
      {modals}
    </div>
  )
}

export default HowDoWePayYouTab
