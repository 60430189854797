import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAngleRight,
  faAngleLeft,
  faPlus,
} from '@fortawesome/pro-light-svg-icons'

export const BackwardIcon = ({ className }) => (
  <FontAwesomeIcon className={className} icon={faAngleLeft} />
)
export const ForwardIcon = ({ className }) => (
  <FontAwesomeIcon className={className} icon={faAngleRight} />
)
export const PlusIcon = ({ className }) => (
  <FontAwesomeIcon className={className} icon={faPlus} />
)
