export default (clientNumber, labRefNumber, patientName, dymo) => {
  let xmlLabel = `<?xml version="1.0" encoding="utf-8"?>
  <DieCutLabel Version="8.0" Units="twips" MediaType="Default">
    <PaperOrientation>Landscape</PaperOrientation>
    <Id>LW_DURABLE_25X89mm</Id>
    <PaperName>1933081 Drbl 1 x 3-1/2 in</PaperName>
    <DrawCommands>
      <RoundRectangle X="0" Y="0" Width="1905" Height="4445" Rx="90.70866" Ry="90.70866"/>
    </DrawCommands>
    <ObjectInfo>
      <TextObject>
        <Name>Lab Order</Name>
        <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
        <BackColor Alpha="0" Red="255" Green="255" Blue="255"/>
        <LinkedObjectName></LinkedObjectName>
        <Rotation>Rotation0</Rotation>
        <IsMirrored>False</IsMirrored>
        <IsVariable>False</IsVariable>
        <HorizontalAlignment>Left</HorizontalAlignment>
        <VerticalAlignment>Middle</VerticalAlignment>
        <TextFitMode>AlwaysFit</TextFitMode>
        <UseFullFontHeight>True</UseFullFontHeight>
        <Verticalized>False</Verticalized>
        <StyledText>
          <Element>
            <String>Client#: ${clientNumber}
Lab Ref#: ${labRefNumber}
Pat Name: ${patientName}</String>
            <Attributes>
              <Font Family="Helvetica" Size="13" Bold="False" Italic="False" Underline="False" Strikeout="False"/>
              <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
            </Attributes>
          </Element>
        </StyledText>
      </TextObject>
      <Bounds X="500" Y="300" Width="3400" Height="1305"/>
    </ObjectInfo>
  </DieCutLabel>`

  return dymo.label.framework.openLabelXml(xmlLabel)
}
