import React, { useEffect, useState } from 'react'
import { CheckList } from '@labsavvyapp/ui-components'

import style from './NewLabOrder.module.css'

export default function NewLabOrder({
  selectedTests,
  selectedPanels,
  selectedCompendiumPanels,
}) {
  const [
    individualTestsChecklistItems,
    setIndividualTestsChecklistItems,
  ] = useState([])

  const [groupsChecklistItems, setGroupsChecklistItems] = useState([])

  /**
   *  Map individual tests for the Checklist component format
   */
  useEffect(() => {
    setIndividualTestsChecklistItems(
      selectedTests.map(({ id, result }, index) => ({
        text: (
          <div key={result.code + index} className={style.checkListTest}>
            <span className={style.checkListTestCode}>{result.code}</span>
            <span>{result.name}</span>
          </div>
        ),
        value: id,
        checked: true,
      })),
    )
  }, [selectedTests])

  /**
   * Map panels for the Checklist component format
   */
  useEffect(() => {
    if (selectedCompendiumPanels.length) return
    setGroupsChecklistItems(
      selectedPanels.map(({ code, name, tests }) => {
        const groupTests = tests.map(({ result }) => ({
          text: (
            <div className={style.checkListTest}>
              <span className={style.checkListTestCode}>{result.code}</span>
              <span>{result.name}</span>
            </div>
          ),
          value: result.code,
        }))

        return {
          group: { code, name, checked: true },
          tests: groupTests,
        }
      }),
    )
  }, [selectedPanels])

  /**
   * Map panels for the Checklist component format
   */
  useEffect(() => {
    if (selectedPanels.length) return
    setGroupsChecklistItems(
      selectedCompendiumPanels.map(({ code, name, results }) => {
        const groupTests = results.map((result) => ({
          text: (
            <div className={style.checkListTest}>
              <span className={style.checkListTestCode}>{result.code}</span>
              <span>{result.name}</span>
            </div>
          ),
          value: result.code,
        }))

        return {
          group: { code, name, checked: true },
          tests: groupTests,
        }
      }),
    )
  }, [selectedCompendiumPanels])

  return (
    <div className={style.newLabOrderContainer}>
      {groupsChecklistItems.length !== 0 &&
        groupsChecklistItems.map(({ group, tests }) => (
          <div className={style.panelContainer} key={group.code}>
            <CheckList
              readOnly
              title={
                <div className={style.checkListTest}>
                  <span className={style.checkListTestCode}>{group.code}</span>
                  <span>{group.name}</span>
                </div>
              }
              group={{
                text: group.name,
                value: group.code,
                checked: group.checked,
              }}
              items={tests}
              showCheckboxes={false}
            />
          </div>
        ))}

      {individualTestsChecklistItems.length !== 0 && (
        <CheckList
          title="Individual Tests"
          items={individualTestsChecklistItems}
          readOnly
          showCheckboxes={false}
        />
      )}
    </div>
  )
}
