import React, { useState } from 'react'
import gql from 'graphql-tag'
import { Grid } from 'semantic-ui-react'
import { useHistory, useLocation } from 'react-router-dom'
import { useMutation } from '@apollo/react-hooks'
import { useIntl } from 'react-intl'

import {
  Form,
  InputField,
  SubmitButton,
  validate,
} from '@labsavvyapp/ui-components'

import style from './RecoverPassword.module.css'
import logo from '../../../assets/labsavvy-logo.svg'
import { SIGN } from '../../../config/routes'

const PROOF_RECOVERY_EMAIL = gql`
  mutation proofRecoveryEmail($email: EmailAddress!, $token: String!) {
    proofRecoveryEmail(email: $email, token: $token)
  }
`

/**
 * Recover password. Step 2: Confirm token.
 */
// FIXME: Fix how server errors are handled (https://labsavvy.atlassian.net/browse/LS-511)
const RecoverConfirmToken = () => {
  const { formatMessage } = useIntl()
  const { push } = useHistory()
  const { search } = useLocation()
  const [proofRecoveryEmail] = useMutation(PROOF_RECOVERY_EMAIL)
  const [formErrors, setFormErrors] = useState({})
  const INITIAL_FORM_DATA = { token: '' }

  /**
   * Form submission.
   */
  const onSubmit = async ({ token }) => {
    const queryParams = new URLSearchParams(search)
    const email = queryParams.get('email')

    try {
      const response = await proofRecoveryEmail({
        variables: {
          email,
          token,
        },
      })
      const proofUuid = response.data.proofRecoveryEmail

      push(`${SIGN.recoverNewPassword}?email=${email}&proof=${proofUuid}`)
    } catch (error) {
      const serverError = new Error(
        JSON.stringify({
          id: 'server_error',
          defaultMessage: error.graphQLErrors[0].message,
        }),
      )
      setFormErrors({
        token: serverError,
      })
    }
  }

  return (
    <Form
      intl={{
        formatMessage,
      }}
      className={style.root}
      errors={formErrors}
      initialFormData={INITIAL_FORM_DATA}
      onSubmit={onSubmit}
    >
      <Grid textAlign="center" verticalAlign="middle">
        <Grid.Column>
          <div className={style.head}>
            <img alt="logo" src={logo} className={style.logo} />
          </div>
          <h3 className={style.title}>Reset password</h3>
          <p className={style.text}>
            Please enter the authentication code you&apos;ve received by e-mail.
          </p>

          <InputField
            name="token"
            placeholder="Authentication code"
            validate={validate.combine(validate.notEmpty())}
          />
          <div className={style.actions}>
            <SubmitButton>Continue</SubmitButton>
          </div>
        </Grid.Column>
      </Grid>
    </Form>
  )
}

export default RecoverConfirmToken
