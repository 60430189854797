import React from 'react'
import { Button, Segment } from 'semantic-ui-react'

import style from './index.module.css'

function NotificationPermissionRequired({ requestPermission }){
    return (
        <div className={style.container}>
          <Segment attached>
            In order to use the Wellness Team feature please allow Notifications.
          </Segment>
          <Button attached='bottom' onClick={() => requestPermission()}>Allow Notifications</Button>
        </div>
    )
}

export default NotificationPermissionRequired
