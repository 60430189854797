import React from 'react'

import Panel, { PanelBody } from '../../../components/Panel/Panel'
import PreformattedLines from '../../../components/PreformattedLines/PreformattedLines'

export default function ProviderDetails({ details }) {
  if (details && details.length)
    return (
      <Panel>
        <PanelBody style={{ padding: '20px 20px 4px' }}>
          {details.map((provider, key) => (
            <PreformattedLines key={key} lines={provider.notes} />
          ))}
        </PanelBody>
      </Panel>
    )
  return <div />
}
