import React from 'react'

import { useQuery } from '@apollo/react-hooks'
import { Icon } from 'semantic-ui-react'
import { ListFrequentlyOrderedPackages } from '../../graphql/lab-orders/queries'
import styles from './DashboardScreen.module.css'

export default function FrequentlyOrderedPackageList() {
  const { data, loading, error } = useQuery(ListFrequentlyOrderedPackages)

  if (loading) {
    return (
      <div className={styles.centerContainer}>
        <Icon name="spinner" loading size="big" />
      </div>
    )
  }

  if (error) {
    return (
      <div className={styles.centerContainer}>
        <h3>Something happened, try again later</h3>
      </div>
    )
  }
  const { packages } = data.webappListPackages
  if (packages.lenght === 0) {
    return (
      <div className={styles.centerContainer}>
        <h3>No packages found</h3>
      </div>
    )
  }

  return (
    <div>
      {packages.map(({ name, commission, package: pack }) => {
        return (
          <div key={`${pack._id}`} className={styles.packageListContainer}>
            <h3 className={styles.packageName}>{name}</h3>
            <div className={styles.packageDetailsContainer}>
              <h3 className={styles.packageLabel}>{pack.provider.name}</h3>
              <h3 className={styles.packageLabel}>${commission}</h3>
              <div className={styles.copyLinkLabel}>copy link for patient</div>
              <div className={styles.viewLabel}>view</div>
            </div>
          </div>
        )
      })}
    </div>
  )
}
