import { useQuery } from '@apollo/react-hooks'
import { camelizeKeys } from 'humps'
import { ContactList } from '../graphql/messaging/queries'

const CONTACT_LIST_POLL_INTERVAL = 60000

export const useContactList = (queryOptions) => {
  const { data: contactListData, loading, refetch } = useQuery(ContactList, {
    pollInterval: CONTACT_LIST_POLL_INTERVAL,
    limit: 50, // TODO refactor ContactList to use pagination with infinitescroll
    page: 1,
    ...queryOptions,
  })

  const contactList =
    (contactListData?.contactList?.contactList &&
      camelizeKeys(contactListData?.contactList?.contactList)) ||
    []

  return {
    contactList,
    loading,
    refetch,
  }
}
