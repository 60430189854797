import gql from 'graphql-tag'

export const GetContactList = gql`
  query GetContactList {
    getContactList
  }
`

export const ListChatMessages = gql`
  query ListChatMessages(
    $to: MongoID!
    $page: NonNegativeInt = 1
    $limit: PositiveInt = 50
  ) {
    listChatMessages(
      to: $to
      page: $page
      limit: $limit
      sort: { timestamp: desc }
    ) {
      messages {
        sender
        to
        text
        timestamp
      }
      page
      pages
      total
    }
  }
`

export const ContactList = gql`
  query ContactList(
    $search: RegExp = ""
    $mobileUserId: MongoID!
    $sort: ContactListSort = { name: { first: asc } }
    $limit: NonNegativeInt
    $page: PositiveInt
  ) {
    contactList(
      input: {
        filter: { search: $search, user_id: $mobileUserId }
        sort: $sort
        limit: $limit
        page: $page
      }
    ) {
      page
      pages
      total
      contactList: data {
        id: _id
        short_id
        name {
          first
          last
          display
        }
        dob
        gender
        address {
          street
          city
          state
          zip
        }
        profile_photo {
          _id
          url
        }
        emails {
          address
        }
        phones {
          number
        }
      }
    }
  }
`
