import React from 'react'
import classnames from 'classnames'
import { format } from 'date-fns'
import { NameInitials } from '@labsavvyapp/ui-components'

import style from './Message.module.css'
import { nl2br } from '../utils'

const Receiver = ({ photo, name, message, date }) => (
  <div
    className={classnames([style.container, style.receiver])}
    data-test="container-message-bubble"
  >
    <div className={style.userInfoContainer}>
      {photo ? (
        <img className={style.photo} src={photo} alt="User" />
      ) : (
        <NameInitials
          firstName={name.first}
          lastName={name.last}
          size={34}
          className={style.nameInitials}
        />
      )}
      <small className={style.name}>{name.display}</small>
    </div>

    <div className={style.messageContainer}>
      <div
        className={style.message}
        // TODO sanitize injected HTML
        dangerouslySetInnerHTML={{ __html: nl2br(message) }}
      />
      <div className={style.date}>
        {date && format(new Date(date), 'HH:mm a')}
      </div>
    </div>
  </div>
)

export default Receiver
