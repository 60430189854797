import React from 'react'

import { ForwardIcon } from '../../../../components/Icons/light'
import style from './ExternalReferenceItem.module.css'

export default function ExternalReferenceItem({
  externalReferenceName,
  logoURL,
  url,
}) {
  return (
    <div className={style.container}>
      <a href={url} target="_blank" rel="noreferrer noopener">
        {
          <div className={style.link}>
            <img
              className={style.logo}
              alt={externalReferenceName}
              src={logoURL}
            />

            <ForwardIcon className={style.externalReferencesAngle} />
          </div>
        }
      </a>
    </div>
  )
}
