import React from 'react'
import { NavLink, generatePath } from 'react-router-dom'
import { Icon } from 'semantic-ui-react'
import { format } from 'date-fns'

import get from 'lodash/get'

import { ListRow } from '@labsavvyapp/ui-components'
import { CLIENTS } from '../../../config/routes'
import style from './ClientNote.module.css'

export default function ClientNote({ clientId, data }) {
  return (
    <ListRow className={style.row}>
      <div className={style.nameDateColumn}>
        <div>{get(data, 'createdBy.name.display')}</div>
        <div>
          {data.createdAt && format(new Date(data.createdAt), 'MM/dd/yyyy')}
        </div>
      </div>

      <div
        data-test="lab-report-note"
        className={style.note}
        dangerouslySetInnerHTML={{
          __html: data.body?.replace('<figure>&nbsp;</figure>', '<hr>') || '',
        }}
      />

      <div className={style.linkContainer}>
        <NavLink
          to={generatePath(CLIENTS.editNote, { clientId, noteId: data.id })}
        >
          <Icon name="edit" />
        </NavLink>
      </div>
    </ListRow>
  )
}
