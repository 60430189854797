import React, { useState, useEffect } from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { useHistory } from 'react-router'
import delay from 'await-delay'
import { SavingModal, ErrorModal, Modal } from '@labsavvyapp/ui-components'
import { useLocation } from 'react-router-dom'
import get from 'lodash/get'
import { Message } from 'semantic-ui-react'

import { PROFILE } from '../../../../config/routes'
import { GetMe } from '../../../../graphql/user/queries.js'
import { GetStripeInfo } from '../../../../graphql/ecommerce/queries.js'
import { StartOnboarding } from '../../../../graphql/ecommerce/mutations.js'

import style from './ConnectTab.module.css'
import stripeConnectButtonLight from './stripe-connect-button/light-on-dark.png'
import stripeConnectButtonLight2x from './stripe-connect-button/light-on-dark@2x.png'
import stripeConnectButtonLight3x from './stripe-connect-button/light-on-dark@3x.png'

const ConnectTab = () => {
  const { push } = useHistory()
  // TODO show current account status, missing steps
  // For example: Add How do we pay you? payment method.
  // Provide more information in order to enable payouts for this account.
  const { data: userData } = useQuery(GetMe)
  const { data: stripeData } = useQuery(GetStripeInfo)
  const hasStripeAccount = get(userData, 'getMe.partner.has_stripe_account')

  const { search } = useLocation()
  const queryParams = new URLSearchParams(search)
  const stripeStatus = queryParams.get('onboarding')

  const [createStripeAccountAndStartOnboarding] = useMutation(StartOnboarding)
  const [showConnectingModal, setShowConnectingModal] = useState(false)
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [apiError, setApiError] = useState('')
  async function handleStripeOnboarding() {
    setShowConnectingModal(true)

    try {
      const onboardingUrl = await createStripeAccountAndStartOnboarding()
      await delay(1000)
      setShowConnectingModal(false)

      window.location.href = onboardingUrl.data.ecommerce_startOnboarding
    } catch (error) {
      setApiError(error.message)
      setShowErrorModal(true)
    }
  }

  const [stripeRedirectMessage, setStripeRedirectMessage] = useState(null)
  useEffect(() => {
    switch (stripeStatus) {
      case 'success':
        setStripeRedirectMessage('You onboarded to Stripe with success.')
        break
      case 'failure':
        setStripeRedirectMessage(
          'An error occurred while onboarding to Stripe. Please contact your admin.',
        )
        break
      default:
        setStripeRedirectMessage(null)
    }
  }, [stripeStatus])

  const howDoYouPayUsLink = (
    <a
      className={style.paymentMethodLink}
      onClick={() => push(PROFILE.billingHowDoYouPayUs)}
    >
      <i>How do you pay us?</i>
    </a>
  )

  const howDoWePayYouLink = (
    <a
      className={style.paymentMethodLink}
      onClick={() => push(PROFILE.billingHowDoWePayYou)}
    >
      <i>How do we pay you?</i>
    </a>
  )

  const formatStripeInfo = (stripeInfo) => {
    return (
      <>
        {stripeInfo.missing_onboarding_info && (
          <Message
            color="teal"
            icon="info"
            content={
              <p>Some information needed for the onboarding is missing</p>
            }
          />
        )}
        {stripeInfo.missing_payout_info && (
          <Message
            color="teal"
            icon="info"
            content={<p>Some information needed for the payout is missing</p>}
          />
        )}
        {stripeInfo.errors && !!stripeInfo.errors.length && (
          <Message
            color="orange"
            icon="warning"
            content={
              <>
                <i>There are some issues with your Stripe information:</i>
                <ul>
                  {stripeInfo.errors.map((error) => (
                    <li key={error}>{error}</li>
                  ))}
                </ul>
              </>
            }
          />
        )}
      </>
    )
  }

  return (
    <div className={style.connectWithStripe}>
      {hasStripeAccount && (
        <p>
          Your account is already connected to stripe. Please enter the payment
          method details for {howDoYouPayUsLink} and {howDoWePayYouLink}. In
          case you want to update any information on stripe please click on the
          Stripe button again.
        </p>
      )}
      {stripeData &&
        formatStripeInfo(get(stripeData, 'ecommerce_getStripeInfo', {}))}
      <a
        onClick={(e) => {
          e.preventDefault()
          handleStripeOnboarding()
        }}
        className={style.stripeButton}
      >
        <img
          height="30px"
          src={stripeConnectButtonLight}
          srcSet={`${stripeConnectButtonLight2x}, ${stripeConnectButtonLight3x}`}
          alt="stripe-connect-button"
        />
      </a>
      <SavingModal
        message="We're setting up your e-commerce account, you will be redirected to stripe onboarding shortly."
        open={showConnectingModal}
      />
      <ErrorModal
        open={showErrorModal}
        message={apiError}
        onCloseClick={() => {
          setShowConnectingModal(false)
          setShowErrorModal(false)
        }}
      />
      {/* Stripe Modal */}
      <Modal
        onCloseClick={() => {
          setStripeRedirectMessage(null)
        }}
        open={!!stripeRedirectMessage}
      >
        {stripeRedirectMessage}
      </Modal>
    </div>
  )
}

export default ConnectTab
