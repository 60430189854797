import gql from 'graphql-tag'

export const ListNotes = gql`
  query ListNotes(
    $limit: PositiveInt = 20
    $page: NonNegativeInt = 1
    $filter: NoteFilter
  ) {
    listNotes(limit: $limit, page: $page, filter: $filter) {
      notes {
        _id
        created_at
        created_by {
          name {
            display
          }
        }
        body
      }
    }
  }
`

export const GetNote = gql`
  query GetNote($id: MongoID!) {
    getNote(id: $id) {
      _id
      created_at
      created_by {
        name {
          display
        }
      }
      body
    }
  }
`
