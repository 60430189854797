import browserCookies from 'browser-cookies'
import { decode } from 'jsonwebtoken'

const isValidToken = (token) => {
  const jwtPayload = decode(token)
  return jwtPayload.exp > Math.round(Date.now() / 1000)
}

export const setCookie = (token) => {
  const jwtPayload = decode(token)

  browserCookies.set('lsat', token, {
    expires: new Date(jwtPayload.exp * 1000),
  })
}

const getCookie = (name) => {
  const cookie = document.cookie.match(`(^|;) ?${name}=([^;]*)(;|$)`)
  return cookie ? cookie[2] : null
}

export const removeCookies = () => {
  browserCookies.erase('lsat')
}

export const isAuthenticated = () => {
  const cookie = getCookie('lsat')
  return cookie && isValidToken(cookie)
}
