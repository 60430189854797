import gql from 'graphql-tag'
import { BankDetailsFragment, CardDetailsFragment } from './fragments'

export const GetPaymentStrategies = gql`
  query GetPaymentStrategies {
    ecommerce_getPaymentStrategies {
      we_pay_you {
        method
        current {
          ...CardDetails
          ...BankDetails
        }
      }
      you_pay_us {
        method
        current {
          ...CardDetails
          ...BankDetails
        }
      }
    }
  }

  ${BankDetailsFragment}
  ${CardDetailsFragment}
`

export const ListInvoices = gql`
  query ListInvoices {
    ecommerce_listInvoices {
      id
      total
      url
      paid
      period_start
      period_end
      created
      status
    }
  }
`

export const GetStripeInfo = gql`
  query GetStripeInfo {
    ecommerce_getStripeInfo {
      account_id
      customer_id
      missing_onboarding_info
      missing_payout_info
      errors
    }
  }
`
