import React from 'react'
import { useHistory } from 'react-router'
import { Button } from '@labsavvyapp/ui-components'

import { SIGN } from '../../../config/routes'
import MainContainer from '../../../components/MainContainer/MainContainer'
import style from './PasswordUpdatePage.module.css'

const PasswordUpdatePage = () => {
  const { push } = useHistory()

  return (
    <MainContainer>
      <header className={style.header}>
        <h1>Success</h1>
      </header>
      <div className={style.contentContainer}>
        <div className={style.actionText}>
          Your password has been updated. You may now login with your new
          password.
        </div>
        <div className={style.buttonsContainer}>
          <Button onClick={() => push(SIGN.in)}>Login</Button>
        </div>
      </div>
    </MainContainer>
  )
}

export default PasswordUpdatePage
