/**
 * Only Credit Card is accepted for this payment strategy
 */
import React from 'react'

import { useMutation, useQuery } from '@apollo/react-hooks'
import get from 'lodash/get'
import { useSavingModals } from '@labsavvyapp/ui-components'
import style from './HowDoYouPayUsTab.module.css'

import { UpdatePaymentYouPayUs } from '../../../../graphql/ecommerce/mutations.js'
import { GetPaymentStrategies } from '../../../../graphql/ecommerce/queries.js'
import PaymentMethod, {
  CARD_TYPE,
} from '../components/PaymentMethod/PaymentMethod'

const HowDoYouPayUsTab = () => {
  const { data } = useQuery(GetPaymentStrategies)
  const youPayUsPaymentMethod = get(
    data,
    'ecommerce_getPaymentStrategies.you_pay_us',
    {},
  )
  const [setYouPayUsPaymentMethod] = useMutation(UpdatePaymentYouPayUs, {
    refetchQueries: [
      {
        query: GetPaymentStrategies,
      },
    ],
  })

  const [modals, { showModals }] = useSavingModals({
    savingMessage:
      "We're saving your How do you pay us? payment method, please wait...",
    savedMessage: 'Your payment method has been saved',
    callback: async ({ paymentMethod }) => {
      await setYouPayUsPaymentMethod({
        variables: paymentMethod,
      })
    },
    onError: (error) => error,
  })

  return (
    <div className={style.container}>
      <h3>How do you pay us?</h3>
      <PaymentMethod
        availableMethods={[CARD_TYPE]}
        paymentMethod={youPayUsPaymentMethod}
        handlePaymentMethod={async (paymentMethod) => {
          showModals({ paymentMethod })
        }}
      />

      {modals}
    </div>
  )
}

export default HowDoYouPayUsTab
