import React, { useEffect } from 'react'
import { Grid } from 'semantic-ui-react'
import { camelizeKeys } from 'humps'
import {
  useHistory,
  useLocation,
  Prompt,
  generatePath,
  useParams,
} from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import {
  Button,
  Form,
  InputField,
  SelectField,
  Tabs,
  TestSelectionModal,
} from '@labsavvyapp/ui-components'
import { fetchNext, hasMore } from '@labsavvyapp/ui-components/lib/utils'

import style from './NewLabOrder.module.css'
import { LAB_REPORTS } from '../../../config/routes'
import ClientSearch from './ClientSearch/ClientSearch'
import TemplateSearch from './TemplateSearch/TemplateSearch'
import NewLabOrder from './NewLabOrder'
import { GetMe } from '../../../graphql/user/queries.js'
import { PartnerCompendium } from '../../../graphql/lab-orders/queries'
import { ListProviders } from '../../../graphql/providers/queries.js'
import useLocalReducer from '../../../hooks/useLocalReducer'
import formatterPrice from '../../../utils/formatter-price'

let fetchNextCompendiumPanels

const TABS = Object.freeze({
  PACKAGES: 0,
  CUSTOM: 1,
})

const initialState = {
  packageSearch: '',
  selectedPackage: null,
  selectedProvider: null,
  selectedPanels: [],
  selectedCompendiumPanels: [],
  selectedTests: [],
  selectedTabIndex: 0,
  orderName: '',
  isModalOpen: false,
  hasMoreCompendiumPanels: undefined,
  searchString: '',
}

export default function NewLabOrderContainer() {
  const urlsParams = useParams()
  const { push } = useHistory()
  const { search } = useLocation()
  const queryParams = new URLSearchParams(search)
  const clientId = queryParams.get('clientId')

  // Fetch user information
  const { data: userData } = useQuery(GetMe)
  const parsedUserData = camelizeKeys(userData)
  const partnerId = parsedUserData?.getMe?.partner?.id

  const [state, setState] = useLocalReducer(
    'new_lab_order',
    (state, action) => ({ ...state, ...action }),
    { ...initialState, selectedClient: clientId || null },
  )

  const {
    packageSearch,
    selectedProvider,
    selectedPanels,
    selectedCompendiumPanels,
    selectedTests,
    selectedTabIndex,
    selectedClient,
    orderName,
    isModalOpen,
    hasMoreCompendiumPanels,
    searchString,
  } = state

  const { data: providersData, loading: providersLoading } = useQuery(
    ListProviders,
  )

  const laboratoryOptions =
    (providersData &&
      providersData.listProviders.providers.map(({ _id, name }) => ({
        key: _id,
        value: _id,
        text: name,
      }))) ||
    []

  const providerId = laboratoryOptions?.[0]?.value

  const {
    data: compendiumPanelsData,
    refetch: refetchPanels,
    fetchMore: fetchMorePanels,
  } = useQuery(PartnerCompendium, {
    skip: !laboratoryOptions || laboratoryOptions.length === 0 || !partnerId,
    variables: {
      limit: 20,
      sort: {},
      filter: {
        search: searchString,
        partnerId: partnerId,
        providerId: providerId,
      },
    },
  })

  const compendiumPanels = camelizeKeys(compendiumPanelsData)
  // Goups > Pagination
  const panelsPage = compendiumPanels && compendiumPanels.partnerCompendium.page
  const panelsPages =
    compendiumPanels && compendiumPanels.partnerCompendium.pages

  useEffect(() => {
    setState({
      hasMoreCompendiumPanels: hasMore({
        page: panelsPage,
        pages: panelsPages,
      }),
    })
    fetchNextCompendiumPanels = () => {
      fetchNext('partnerCompendium', 'panels', {
        page: panelsPage,
        fetchMore: fetchMorePanels,
        filter: {
          search: searchString,
          partnerId: partnerId,
          providerId: providerId,
        },
      })
    }
  }, [panelsPage, panelsPages])

  useEffect(() => {
    if (partnerId && providerId) {
      refetchPanels()
    }
  }, [searchString])

  // Helpers
  const isDirty = () => {
    const { selectedPackage, selectedClient, selectedCompendiumPanels } = state
    return Boolean(
      (selectedPackage || selectedCompendiumPanels.length) && selectedClient,
    )
  }

  const clearChanges = () => {
    setState({
      packageSearch: initialState.packageSearch,
      selectedPackage: initialState.selectedPackage,
      selectedTests: initialState.selectedTests,
      selectedPanels: initialState.selectedPanels,
      selectedCompendiumPanels: initialState.selectedCompendiumPanels,
    })
  }

  // Filter handlers
  const onClientSelect = (client) => {
    setState({ selectedClient: client ? client.id : selectedClient })
  }

  const handleCompendiumSearch = (value) => setState({ searchString: value })

  const onTemplateSelect = ({ package: { categories, id, lsPrice } }) => {
    const panels = categories.flatMap(({ panels }) => panels)

    const packagePanels =
      panels &&
      panels.map((panel) => ({
        code: panel.code,
        name: panel.name,
        price: lsPrice,
        tests: panel.tests.map((test) => ({
          id,
          order: test.order,
          result: test.result,
        })),
      }))
    packagePanels && setState({ selectedPanels: packagePanels })
    setState({ selectedPackage: id })
  }

  const reviewOrder = async (values) => {
    const { selectedClient, selectedPackage } = state

    const data = {
      patient_id: selectedClient,
    }

    if (selectedTabIndex === TABS.CUSTOM) {
      data.name = orderName
      data.order_codes = selectedCompendiumPanels.map(({ code }) => code)
    } else {
      data.package_id = selectedPackage
    }

    data.order_items_data = [
      ...selectedPanels.map(({ code }) => generateOrderItem(code, values)),
      ...selectedCompendiumPanels.map(({ code }) =>
        generateOrderItem(code, values),
      ),
    ]

    setState({ reviewSubmitData: data })
    setTimeout(function () {
      push(LAB_REPORTS.review)
    }, 0)
  }

  const onResultsSubmit = (code) => {
    const panels = compendiumPanels?.partnerCompendium?.panels.filter(
      (item) => item.code === code,
    )
    setState({
      selectedCompendiumPanels: [...selectedCompendiumPanels, ...panels],
      isModalOpen: false,
    })
  }

  const generateOrderItem = (code) => ({
    order_code: code,
  })

  const handleLaboratoryChange = (value) => {
    setState({ selectedProvider: value })
  }

  const handleOrderNameChange = (value) => {
    setState({ orderName: value })
  }

  const tabLayoutItems = [
    {
      name: 'Packages',
      url: `${LAB_REPORTS.new}/packages`,
    },
    {
      name: 'Custom',
      url: `${LAB_REPORTS.new}/custom`,
    },
  ]

  function handleTabClick({ index, url }) {
    clearChanges()
    setState({ selectedTabIndex: index })
    push(
      generatePath(url, {
        ...urlsParams,
      }),
    )
  }

  const SubTotalPrice = () => {
    const total = selectedCompendiumPanels.length
      ? selectedCompendiumPanels.reduce(
          (price, test) => price + (test.price || 0),
          0,
        )
      : selectedPanels.reduce((price, test) => price + (test.price || 0), 0)
    return formatterPrice(total)
  }

  return (
    <div className={style.pageContainer}>
      <div className={style.formContainer}>
        <Form
          className={style.form}
          initialFormData={{ order_name: orderName }}
        >
          <Grid padded className={style.partnerAndClientFilters}>
            <Grid.Row className={style.pageHeaderContainer}>
              <Grid.Column tablet={16} computer={5} largeScreen={5}>
                <div className={style.pageTitle}>
                  <h2>New Lab Order</h2>
                </div>
              </Grid.Column>
              <Grid.Column tablet={8} computer={5} largeScreen={5}>
                <div className={style.filterColumn}>
                  <div className={style.filterName}>Laboratory</div>
                  <SelectField
                    className={style.laboratoryDropdown}
                    name="provider_id"
                    onChange={handleLaboratoryChange}
                    options={laboratoryOptions}
                    value={laboratoryOptions[0] && laboratoryOptions[0].value}
                    loading={laboratoryOptions.length === 0}
                  />
                </div>
              </Grid.Column>
              <Grid.Column tablet={8} computer={5} largeScreen={5}>
                <div className={style.filterColumn}>
                  <div className={style.filterName}>Client</div>
                  <ClientSearch
                    filters={{ id: selectedClient }}
                    onSelect={onClientSelect}
                  />
                </div>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column
                computer={3}
                tablet={5}
                className={style.tabsContainer}
              >
                <Tabs
                  items={tabLayoutItems}
                  selectedTabIndex={selectedTabIndex}
                  onClick={handleTabClick}
                  contentClassName={style.tabsContentClassName}
                  headerClassName={style.tabsHeaderClassName}
                />
              </Grid.Column>
              <Grid.Column computer={13} tablet={10}>
                <div className={style.filterColumn}>
                  {selectedTabIndex === TABS.CUSTOM ? (
                    <div className={style.customOrderInputContainer}>
                      <div className={style.customOrderInput}>
                        <InputField
                          label="Order name (optional)"
                          name="order_name"
                          placeholder="e.g. Custom order"
                          onChange={handleOrderNameChange}
                          value={orderName}
                        />
                      </div>

                      <div
                        className={style.plusIcon}
                        onClick={() => setState({ isModalOpen: true })}
                      >
                        +
                      </div>
                    </div>
                  ) : (
                    <TemplateSearch
                      label="Choose a package"
                      search={packageSearch}
                      onChange={(packageSearch) => setState({ packageSearch })}
                      onSelect={onTemplateSelect}
                      providersLoading={providersLoading}
                      providerId={selectedProvider}
                    />
                  )}
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>

          <div className={style.packagesContainer}>
            {selectedTabIndex === TABS.CUSTOM ? (
              <NewLabOrder
                packageSearch={packageSearch}
                selectedPanels={selectedPanels}
                selectedCompendiumPanels={selectedCompendiumPanels}
                selectedTests={selectedTests}
              />
            ) : (
              <NewLabOrder
                packageSearch={packageSearch}
                selectedPanels={selectedPanels}
                selectedCompendiumPanels={selectedCompendiumPanels}
                selectedTests={selectedTests}
              />
            )}
          </div>
          {selectedCompendiumPanels?.length || selectedPanels?.length ? (
            <div className={style.subtotalContainer}>
              <p className={style.textSubtotal}>
                Subtotal (does not include additional fees, draw fees):{' '}
                <b>{SubTotalPrice()}</b>
              </p>
            </div>
          ) : null}
        </Form>
      </div>

      <div className={style.buttonsContainer}>
        <Button
          data-test="button-submit-order"
          disabled={!isDirty()}
          onClick={reviewOrder}
        >
          Review Order
        </Button>

        <Button
          data-test="cancel-button"
          variant="basic"
          onClick={() => push(LAB_REPORTS.base)}
        >
          Cancel
        </Button>
      </div>

      <TestSelectionModal
        open={isModalOpen}
        items={compendiumPanels?.partnerCompendium?.panels}
        onNext={fetchNextCompendiumPanels}
        hasMore={hasMoreCompendiumPanels}
        onSubmit={onResultsSubmit}
        onSearch={handleCompendiumSearch}
        onClose={() => setState({ isModalOpen: false })}
      />

      <Prompt
        when={isDirty()}
        message={(location) => {
          return location.pathname.startsWith(LAB_REPORTS.review)
            ? true
            : 'You have unsaved changes! Are you sure you want to leave?'
        }}
      />
    </div>
  )
}
