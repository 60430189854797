import React from 'react'

import Panel, {
  PanelHeader,
  PanelBody,
} from '../../../../components/Panel/Panel'
import ExternalReferenceItem from './ExternalReferenceItem'
import style from './ExternalReferences.module.css'

const EXTERNAL_REFERENCES_ORDER = {
  labtestsonline: 0,
  medline: 1,
  wikipedia: 2,
}

const externalReferencesOrdered = []

export default function ExternalReferences({ externalReferences, result }) {
  /**
   * Checks if some External Reference has URL.
   *
   * @param {object} externalReferences External References.
   *
   * @returns {boolean} Returns true if some External Reference has URL, otherwise returns false.
   */
  function hasExternalReferences(externalReferences) {
    let isUrlEmpty = true
    Object.keys(externalReferences).forEach((thisProp) => {
      const value =
        externalReferences[thisProp] && externalReferences[thisProp].url
      if (value) {
        isUrlEmpty = false
      }
    })
    return !isUrlEmpty
  }

  /**
   * External Reference order (array index).
   *
   * @param {string} externalReference External Reference name.
   *
   * @returns {number} Returns the array index.
   */
  function getExternalReferenceOrder(externalReference) {
    return EXTERNAL_REFERENCES_ORDER[externalReference]
  }

  /**
   * Adds an External Reference to the array at a given index.
   *
   * @param {string} externalReference External Reference name.
   * @param {object} values            External Reference values.
   */
  function addExternalReference(externalReference, values) {
    const index = getExternalReferenceOrder(externalReference)
    const obj = {
      name: externalReference,
      ...values,
    }
    externalReferencesOrdered[index] = obj
  }

  /**
   * External References ordered.
   *
   * @param {object} externalReferences Original External References.
   *
   * @returns {object[]} Returns an ordered External References array.
   */
  function getExternalReferencesOrdered(externalReferences) {
    for (const externalReference in externalReferences) {
      addExternalReference(
        externalReference,
        externalReferences[externalReference],
      )
    }
    return externalReferencesOrdered
  }

  /**
   * External References rendered.
   *
   * @param {object} externalReferences Original External References.
   *
   * @returns {string} Returns the rendered External References.
   */
  function getExternalReferencesRendered(externalReferences) {
    const externalReferencesOrdered = getExternalReferencesOrdered(
      externalReferences,
    )
    return externalReferencesOrdered.map(
      (externalReference) =>
        externalReference &&
        externalReference.url && (
          <ExternalReferenceItem
            key={externalReference.name}
            externalReferenceName={externalReference.name}
            logoURL={externalReference.logo && externalReference.logo.url}
            url={externalReference.url}
          />
        ),
    )
  }

  if (!externalReferences || !Object.keys(externalReferences).length)
    return null

  return (
    (hasExternalReferences(externalReferences) && (
      <Panel className={style.container}>
        <PanelHeader>
          <h2>External References</h2>
        </PanelHeader>

        <div className={style.subTitle}>
          <div className={style.info}>
            Find more information about <b>{result && result.name}</b> from the
            following sources:
          </div>
        </div>

        <PanelBody>
          {getExternalReferencesRendered(externalReferences)}
        </PanelBody>
      </Panel>
    )) ||
    null
  )
}
