import gql from 'graphql-tag'

export const CreatePatient = gql`
  mutation CreatePatient($data: PatientInput!) {
    createPatient(data: $data) {
      _id
    }
  }
`

export const UpdatePatient = gql`
  mutation UpdatePatient($id: MongoID!, $data: PatientInput!) {
    updatePatient(id: $id, data: $data) {
      _id
    }
  }
`
export const DeletePatient = gql`
  mutation DeletePatient($id: MongoID!) {
    deletePatient(id: $id) {
      _id
    }
  }
`
