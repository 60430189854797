import React, { useState, useEffect } from 'react'
import { Dropdown } from 'semantic-ui-react'

import CardPayment from '../CardPayment/CardPayment'
import CurrentCardPayment from '../CurrentCardPayment/CurrentCardPayment'
import ACHPayment from '../ACHPayment/ACHPayment'
import CurrentACHPayment from '../CurrentACHPayment/CurrentACHPayment'

import style from './PaymentMethod.module.css'

import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
const ELEMENTS_OPTIONS = {
  fonts: [
    {
      cssSrc: 'https://fonts.googleapis.com/css?family=Roboto',
    },
  ],
}
const stripePromise =
  (process.env.REACT_APP_USE_ECOMMERCE === 'true' &&
    loadStripe(process.env.REACT_APP_STRIPE_API_KEY)) ||
  Promise.resolve()

export const CARD_TYPE = 'card'
export const BANK_TYPE = 'bank'

const typeMap = {
  [CARD_TYPE]: 'Card',
  [BANK_TYPE]: 'Bank',
}

const PaymentMethod = ({
  paymentMethod,
  handlePaymentMethod,
  availableMethods,
}) => {
  const [statePaymentMethod, setStatePaymentMethod] = useState(paymentMethod)
  useEffect(() => {
    setStatePaymentMethod(paymentMethod)
  }, [paymentMethod])
  const isCardPayment =
    statePaymentMethod && statePaymentMethod.method === CARD_TYPE
  const isBankPayment =
    statePaymentMethod && statePaymentMethod.method === BANK_TYPE
  const hasCurrent =
    statePaymentMethod &&
    statePaymentMethod.current &&
    !!statePaymentMethod.current.id
  return (
    <>
      <Dropdown
        className={style.dropdown}
        data-test="dropdown-select-method"
        placeholder="Select Method"
        search
        selection
        value={statePaymentMethod && statePaymentMethod.method}
        options={availableMethods.map((method) => ({
          key: method,
          value: method,
          text: typeMap[method],
        }))}
        onChange={(e, data) => setStatePaymentMethod({ method: data.value })}
      />
      {isCardPayment && hasCurrent && (
        <CurrentCardPayment
          current={statePaymentMethod.current}
          onReset={() => setStatePaymentMethod({ method: CARD_TYPE })}
        />
      )}
      {isCardPayment && !hasCurrent && (
        <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
          <CardPayment
            onChange={(data) =>
              handlePaymentMethod({ ...data, method: CARD_TYPE })
            }
          />
        </Elements>
      )}
      {isBankPayment && hasCurrent && (
        <CurrentACHPayment
          current={statePaymentMethod.current}
          onReset={() => setStatePaymentMethod({ method: BANK_TYPE })}
        />
      )}
      {isBankPayment && !hasCurrent && (
        <ACHPayment
          onChange={(data) =>
            handlePaymentMethod({ ...data, method: BANK_TYPE })
          }
        />
      )}
    </>
  )
}

export default PaymentMethod
