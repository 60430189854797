import { useReducer, useEffect } from 'react'

export default function useLocalReducer(key, reducer, initialValue = {}) {
  const [storedValue, setStoredValue] = useReducer(
    reducer,
    initialValue,
    (initialValue) => {
      try {
        const sessionData = window.sessionStorage.getItem(key)
        return sessionData ? JSON.parse(sessionData) : initialValue
      } catch (error) {
        console.error('LocalState get error: ', error)
        return initialValue
      }
    },
  )

  useEffect(() => {
    try {
      window.sessionStorage.setItem(key, JSON.stringify(storedValue))
    } catch (error) {
      console.error('LocalState set error: ', error)
    }
  }, [storedValue])

  const clearStoredValue = () => {
    try {
      window.sessionStorage.removeItem(key)
    } catch (error) {
      console.error('LocalState clear error: ', error)
    }
  }

  return [storedValue, setStoredValue, clearStoredValue]
}
