import gql from 'graphql-tag'

export const ReleaseLabOrder = gql`
  mutation ReleaseLabOrder($labOrderId: MongoID!) {
    releaseLabOrder(labOrderId: $labOrderId) {
      _id
    }
  }
`

export const CreateLabOrder = gql`
  mutation CreateLabOrder($data: LabOrderInput!) {
    createLabOrder(data: $data) {
      _id
    }
  }
`

export const CreateLabOrderProduct = gql`
  mutation CreateLabOrderProduct($data: LabOrderProductInput!) {
    createLabOrderProduct(data: $data) {
      _id
    }
  }
`

export const ConfirmAOESubmission = gql`
  mutation ConfirmAOESubmission($labOrderId: MongoID!, $shortId: String!) {
    confirmAOESubmission(labOrderId: $labOrderId, shortId: $shortId) {
      _id
    }
  }
`
