import React from 'react'

import style from './DetailsLayout.module.css'
import Header from './Header'
import Panel from './Panel'
import Messages from './Messages'

export const DetailsLayout = ({ children }) => {
  const { header, messages, panels, footer } = children.reduce(
    (filteredChildren, currentChildren) => {
      const { ...newChildren } = filteredChildren

      switch (currentChildren.type.layoutElementName) {
        case 'Header': {
          return { ...newChildren, header: currentChildren }
        }

        case 'Messages': {
          return { ...newChildren, messages: currentChildren }
        }

        case 'Panel': {
          if (Array.isArray(newChildren.panels)) {
            return {
              ...newChildren,
              panels: [...newChildren.panels, currentChildren],
            }
          } else {
            return {
              ...newChildren,
              panels: [currentChildren],
            }
          }
        }

        case 'Footer': {
          return { ...newChildren, footer: currentChildren }
        }

        default: {
          return newChildren
        }
      }
    },
    {},
  )

  return (
    <div className={style.container}>
      {header}

      {messages}

      <div className={style.panels}>{panels}</div>

      {footer}
    </div>
  )
}

DetailsLayout.Header = Header
DetailsLayout.Panel = Panel
DetailsLayout.Messages = Messages
