import React, { useState, useEffect } from 'react'
import * as d3 from 'd3'
import * as shape from 'd3-shape'
import { scaleTime, scaleLinear } from 'd3-scale'
import { axisBottom, axisRight } from 'd3-axis'

import style from './Chart.module.css'

const margin = {
  top: 0,
  right: 50,
  bottom: 43,
  left: 50,
}

const scaleMargins = {
  minBottomValue: 0.1,
  maxTopValue: 0.9,
  bottom: 0.15,
  top: 0.2,
}

const COLOR = {
  normal: 'rgb(80, 210, 194)',
  abnormal: 'rgb(254, 56, 36)',
}

const newRangesForAllValuesInsideFirstRange = [
  {
    // 52%
    min: 0,
    max: 0.52,
  },
  {
    // 32%
    min: 0.52,
    max: 0.84,
  },
  {
    // 16%
    min: 0.84,
    max: 1,
  },
]

const newRangesForAllValuesInsideLastRange = [
  {
    // 16%
    min: 0,
    max: 0.16,
  },
  {
    // 32%
    min: 0.16,
    max: 0.48,
  },
  {
    // 52%
    min: 0.48,
    max: 1,
  },
]

const xAxisRef = React.createRef()
const yAxisRef = React.createRef()

function stringToDate(stringDate) {
  return new Date(stringDate)
}

function uniqueRanges(array) {
  return [...new Set(array)]
}

function aggregateAllRanges(ranges) {
  const allRanges = []
  ranges.map((elem) => allRanges.push(elem.min, elem.max))
  return uniqueRanges(allRanges)
}

function yAxisColor(ranges, value) {
  const matchRange = ranges.find((range) => range.max === value)
  return COLOR[matchRange.type]
}

/**
 * Map a numeric range onto another.
 */
function mapRangeValue(x, inMin, inMax, outMin, outMax) {
  const rangeValue =
    ((x - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin
  return rangeValue.toFixed(2)
}

/**
 * Map new ranges.
 */
function mapNewRanges(oldRanges, newRanges) {
  return oldRanges.map((elem, index) => {
    return {
      type: elem.type,
      min: newRanges[index].min,
      max: newRanges[index].max,
    }
  })
}

/**
 * Map new values (pattern: all values inside first range).
 */
function mapNewValuesForAllValuesInsideFirstRange(
  oldData,
  oldRanges,
  newRanges,
) {
  return oldData.map((elem) => {
    const newMappedValue = mapRangeValue(
      elem.value,
      oldRanges[0].max,
      0,
      newRanges[0].max,
      0,
    )
    return {
      type: elem.type,
      date: elem.date,
      value: newMappedValue,
      tooltip: elem.tooltip,
    }
  })
}

/**
 * Map new values (pattern: all values inside last range).
 */
function mapNewValuesForAllValuesInsideLastRange(
  oldData,
  oldRanges,
  newRanges,
) {
  return oldData.map((elem) => {
    const newMappedValue = mapRangeValue(
      elem.value,
      oldRanges[2].min,
      1,
      newRanges[2].min,
      1,
    )
    return {
      type: elem.type,
      date: elem.date,
      value: newMappedValue,
      tooltip: elem.tooltip,
    }
  })
}

function HorizontalRanges({ ticks = [], y, svg }) {
  return (
    <g>
      {[...ticks].map((tick) => (
        <line
          key={`hl-${tick}`}
          x1="0%"
          x2="100%"
          y1={y(tick)}
          y2={y(tick)}
          strokeWidth={1}
          stroke="rgba(0,0,0,0.1)"
          {...svg}
        />
      ))}
    </g>
  )
}

function Tooltip({ x, y, tooltip }) {
  const width = 40
  const height = 30
  const inlineStyle = {
    position: 'absolute',
    top: y - height - 4,
    left: x - width / 2,
    width,
    height,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#ffffff',
    background: '#594a9b',
    borderRadius: 10,
    fontSize: '10px',
  }

  return (
    <div className={style.tooltipArrow} style={inlineStyle}>
      {tooltip}
    </div>
  )
}

function drawXAxis(data, scaleX) {
  // Map dates to tick values
  const bottomAxisTickValues = data.map((result) => stringToDate(result.date))

  // Create the Axis
  const xAxis = axisBottom(scaleX)
    .tickValues(bottomAxisTickValues)
    .tickFormat('')
  // Add the X Axis
  const xAxisElement = d3.select(xAxisRef.current)
  xAxisElement.call(xAxis)

  // Remove unnecessary elements from default axis
  xAxisElement.select('path').remove()
  xAxisElement.selectAll('.tick line').remove()

  // Format xAxis text multiline
  xAxisElement.selectAll('.tick text').call((text) => {
    text.each(function (d) {
      const tickTextElement = d3.select(this)
      tickTextElement
        .append('tspan')
        .attr('x', 0)
        .attr('dy', '.8em')
        .text(d3.timeFormat('%b %d')(d))
      tickTextElement
        .append('tspan')
        .attr('x', 0)
        .attr('dy', '1.2em')
        .text(d3.timeFormat('%Y')(d))
    })
  })
}

function drawYAxis(ranges, data, height) {
  const yScale = d3
    .scaleLinear()
    .domain([data[data.length - 1], 0])
    .range([margin.top, height - margin.bottom])

  // Create the Axis
  const yAxis = axisRight(yScale).tickValues(data)

  const yAxisElement = d3.select(yAxisRef.current)

  // Add the Y Axis
  yAxisElement.call(yAxis)

  yAxisElement.selectAll('.tick').call((text) => {
    text.each(function (d, index) {
      const tickGroup = d3.select(this)

      // The line height is the distance until the precedent element
      const lineHeight =
        index > 0 ? yScale(data[index - 1]) - yScale(d) - 2 : margin.bottom

      tickGroup.append('rect').attr('width', 10).attr('height', lineHeight)

      if (index === 0) {
        tickGroup.attr('fill', 'silver')
      } else {
        tickGroup.attr('fill', yAxisColor(ranges, d))
      }
    })
  })

  // Remove unnecessary elements from the default axis
  yAxisElement.select('path').remove()
  yAxisElement.selectAll('line').remove()
  yAxisElement.selectAll('text').remove()
}

export function Chart({ width, height, data, ranges }) {
  let newRanges = ranges
  let newData = data

  // Rule 2 - Minimum Range Height for 3 Range Charts
  if (ranges.length === 3) {
    // Determine whether the values are all in the range above or in the range below
    const firstRange = ranges[0].max
    const lastRange = ranges[2].min
    const allValuesInsideFirstRange = data.every(
      (elem) => elem.value <= firstRange,
    )
    const allValuesInsideLastRange = !allValuesInsideFirstRange
      ? data.every((elem) => elem.value >= lastRange)
      : false
    if (allValuesInsideFirstRange) {
      // First range
      newRanges = mapNewRanges(ranges, newRangesForAllValuesInsideFirstRange)
      newData = mapNewValuesForAllValuesInsideFirstRange(
        data,
        ranges,
        newRangesForAllValuesInsideFirstRange,
      )
    } else if (allValuesInsideLastRange) {
      // Last range
      newRanges = mapNewRanges(ranges, newRangesForAllValuesInsideLastRange)
      newData = mapNewValuesForAllValuesInsideLastRange(
        data,
        ranges,
        newRangesForAllValuesInsideLastRange,
      )
    }
  }

  const uniqueRanges = aggregateAllRanges(newRanges)
  const [tooltip, setTooltip] = useState({
    visible: false,
  })

  const scaleX = scaleTime()
    .domain([
      stringToDate(newData[0].date),
      stringToDate(newData[newData.length - 1].date),
    ])
    .range([margin.left, width - margin.right])

  const originalScaleY = scaleLinear()
    .domain([uniqueRanges[0], uniqueRanges[uniqueRanges.length - 1]])
    .range([height - margin.bottom, margin.top])

  // Determine if some value are close to the limits
  const hasLimitValues = newData.some(
    (elem) =>
      elem.value <= scaleMargins.minBottomValue ||
      elem.value >= scaleMargins.maxTopValue,
  )
  const scaleY = hasLimitValues
    ? scaleLinear()
        .domain([
          uniqueRanges[0] - scaleMargins.bottom,
          uniqueRanges[uniqueRanges.length - 1] + scaleMargins.top,
        ])
        .range([height - margin.bottom, margin.top])
    : originalScaleY

  // Results value path
  const resultsLine = shape
    .line()
    .x((d) => scaleX(stringToDate(d.date)))
    .y((d) => scaleY(d.value))
    .curve(shape.curveNatural)(newData)

  useEffect(() => {
    // Draw X Axis
    drawXAxis(newData, scaleX)

    // Draw Y Axis
    drawYAxis(newRanges, uniqueRanges, height)
  }, [newData, newRanges, uniqueRanges])

  // Handle mouse events
  const showTooltip = (event, tooltip) => {
    const {
      cx: {
        baseVal: { value: x },
      },
      cy: {
        baseVal: { value: y },
      },
    } = event.target
    setTooltip({
      x,
      y,
      tooltip,
      visible: true,
    })
  }

  return (
    <div className={style.chartContainer} data-test="chart-container">
      {tooltip.visible && (
        <Tooltip x={tooltip.x} y={tooltip.y} tooltip={tooltip.tooltip} />
      )}

      <svg className={style.chart} {...{ width, height }}>
        <HorizontalRanges ticks={uniqueRanges} y={originalScaleY} />
        <path
          d={resultsLine}
          fill="transparent"
          stroke="#b3b2d2"
          strokeWidth={1}
        />

        {newData.map((item, index) => (
          <circle
            className={style.circle}
            key={index}
            cx={scaleX(stringToDate(item.date))}
            cy={scaleY(item.value)}
            r={5}
            strokeWidth={4}
            stroke={COLOR[item.type]}
            fill="white"
            onFocus={() => true}
            onBlur={() => true}
            onClick={(event) => showTooltip(event, item.tooltip)}
          />
        ))}

        <g>
          <g
            ref={xAxisRef}
            transform={`translate(0, ${height - margin.bottom})`}
          />
          <g ref={yAxisRef} />
        </g>
      </svg>
    </div>
  )
}
