import gql from 'graphql-tag'

export const StoreRegistrationToken = gql`
  mutation StoreRegistrationToken($token: String!) {
    storeRegistrationToken(registrationToken: $token)
  }
`

export const SendChatMessage = gql`
  mutation SendChatMessage($to: ID!, $text: String!, $timestamp: DateTime!) {
    sendChatMessage(to: $to, text: $text, timestamp: $timestamp)
  }
`
