import React from 'react'
import { Switch, Route } from 'react-router'

import withMainLayout from '../layouts/Main/withMainLayout'
import NotesFormPage from '../pages/Notes/NotesFormPage'
import { NOTES } from '../config/routes'

const NotesRoutes = ({
  path,
  parentPath,
  me,
  loggedIn,
  fallbackRouteComponent,
}) => (
  <Route
    path={path}
    render={({ match }) => (
      <Switch>
        <Route
          exact
          path={[
            `${match.path}/${parentPath}/${NOTES.new}`,
            `${match.path}/${parentPath}/${NOTES.edit}`,
          ]}
          component={() => withMainLayout(NotesFormPage, { me, loggedIn })}
        />
        <Route
          component={() =>
            withMainLayout(fallbackRouteComponent, { me, loggedIn })
          }
        />
      </Switch>
    )}
  />
)

export default NotesRoutes
