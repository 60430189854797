import gql from 'graphql-tag'

export const UpdatePaymentYouPayUs = gql`
  mutation UpdatePaymentYouPayUs($method: MethodEnum!, $token: String!) {
    ecommerce_updateYouPayUs(token: $token, method: $method)
  }
`
export const UpdatePaymentWePayYou = gql`
  mutation UpdatePaymentWePayYou(
    $method: MethodEnum!
    $token: String!
    $plaidAccountId: String
  ) {
    ecommerce_updateWePayYou(
      method: $method
      token: $token
      plaid_account_id: $plaidAccountId
    )
  }
`
export const StartOnboarding = gql`
  mutation StartOnboarding {
    ecommerce_startOnboarding
  }
`

export const CreatePlaidLink = gql`
  mutation CreatePlaidLink {
    ecommerce_createPlaidLink
  }
`
