import React from 'react'
import { List, ListRow } from '@labsavvyapp/ui-components'
import { useQuery } from '@apollo/react-hooks'
import classnames from 'classnames'
import { format } from 'date-fns'

import { ListInvoices } from '../../../../graphql/ecommerce/queries.js'
import style from './InvoicesTab.module.css'

const populateListRow = ({ id, created, status, total, url }) => (
  <ListRow key={id} className={style.row}>
    <div className={classnames(style.invoiceColumn, style.dateColumn)}>
      {format(new Date(created), 'MMM d, yyyy')}
    </div>
    <div className={style.invoiceColumn}>
      {(status &&
        status.length &&
        status.charAt(0).toUpperCase() + status.slice(1)) ||
        '-'}
    </div>
    <div className={style.invoiceColumn}>
      {Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(total)}
    </div>
    <div className={style.invoiceColumn}>
      {url && (
        <a
          className={style.viewInvoiceButton}
          href={url}
          target="_blank"
          rel="noreferrer noopener"
          disabled={true}
        >
          View Invoice
        </a>
      )}
      {!url && (
        <span className={style.viewInvoiceButton}>Upcoming Invoice</span>
      )}
    </div>
  </ListRow>
)

const noInvoicesRow = (
  <ListRow key={'no_invoices'} className={style.row}>
    There are currently no invoices available.
  </ListRow>
)

const loadingInvoices = (
  <ListRow key={'loading_invoices'} className={style.row}>
    Loading Invoices...
  </ListRow>
)

function InvoicesTab() {
  const { data: invoiceData, loading } = useQuery(ListInvoices)
  const invoices = (invoiceData && invoiceData.ecommerce_listInvoices) || []

  return (
    <div className={style.container}>
      <List>
        {(loading && loadingInvoices) ||
          (!invoices.length && noInvoicesRow) ||
          invoices.map((invoice) => populateListRow(invoice))}
      </List>
    </div>
  )
}
export default InvoicesTab
