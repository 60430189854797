import React from 'react'
import { format } from 'date-fns'
import { Icon } from 'semantic-ui-react'
import { NavLink, useParams, generatePath } from 'react-router-dom'
import { Card } from '@labsavvyapp/ui-components'
import get from 'lodash/get'

import { LAB_REPORTS } from '../../../config/routes'
import style from './LabReportNote.module.css'

export default function LabReportNote({ data }) {
  const { labReportId } = useParams()
  const { id: noteId } = data

  return (
    <Card shadowed className={style.container}>
      <div className={style.header}>
        <div>{get(data, 'createdBy.name.display')}</div>
        <div>{format(new Date(data.createdAt), 'MMM dd, yyyy')}</div>
      </div>
      <div
        data-test="lab-report-note"
        className={style.text}
        dangerouslySetInnerHTML={{ __html: data.body || '' }}
      />
      <div className={style.actions}>
        <NavLink
          data-test="icon-edit-note"
          to={generatePath(LAB_REPORTS.editNote, { labReportId, noteId })}
        >
          <Icon className={style.icon} name="edit" />
        </NavLink>
      </div>
    </Card>
  )
}
