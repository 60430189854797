import React, { useCallback } from 'react'
import { usePlaidLink } from 'react-plaid-link'
import { useMutation } from '@apollo/react-hooks'

import { Button } from '@labsavvyapp/ui-components'

import { CreatePlaidLink } from '../../../../../graphql/ecommerce/mutations.js'

export default function ACHPayment({ onChange }) {
  const { data } = useMutation(CreatePlaidLink)
  const plaidLinkToken = data && data.ecommerce_createPlaidLink

  const onExit = useCallback((event) => {
    // eslint-disable-next-line no-console
    console.log('handleOnExit', event)
  }, [])

  const onSuccess = useCallback(
    (token, metadata) => {
      onChange({ token, plaid_account_id: metadata.account_id })
    },
    [onChange],
  )

  const config = {
    token: plaidLinkToken,
    clientName: 'LabSavvy',
    env: process.env.REACT_APP_PLAID_ENV,
    product: ['auth', 'transactions'],
    publicKey: process.env.REACT_APP_PLAID_PUBLIC_KEY,
    onSuccess,
    onExit,
  }

  const { open, ready, error } = usePlaidLink(config)
  return (
    <Button
      data-test="button-connect-bank-account"
      disabled={!ready || error}
      onClick={() => open()}
    >
      Connect Bank Account
    </Button>
  )
}
