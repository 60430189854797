import React from 'react'

import { Button } from '@labsavvyapp/ui-components'
import CardContainer from './CardContainer'
import FrequentlyOrderedPackageList from './FrequentlyOrderedPackageList'
import styles from './DashboardScreen.module.css'

const newPackageDetails =
  'Submit a request for a new test package. You will be able to build a package with tests, and panels.'
const supportDetails =
  'We are here to help. If you have questions, suggestions, or requests, please feel free to call us or email us.'

export default function DashboardScreen() {
  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div className={styles.title}>DASHBOARD</div>
      </div>
      <div className={styles.rowDetailsContainer}>
        <CardContainer>
          <h3 className={styles.packageTitle}>Request New Test Package</h3>
          <h4 className={styles.packageDetails}>{newPackageDetails}</h4>
          <Button>REQUEST NEW PACKAGE</Button>
        </CardContainer>
        <CardContainer>
          <h3 className={styles.packageTitle}>Partner Support</h3>
          <h4 className={styles.packageDetails}>{supportDetails}</h4>
          <h4 className={styles.packageDetails}>+1 (202) 964-2950</h4>
          <h4 className={styles.packageDetails}>support@labsavvy.com</h4>
        </CardContainer>
      </div>
      <div className={styles.rowDetailsContainer}>
        <h3 className={styles.frequentTitle}>Frequently Ordered Packages</h3>
        <div className={styles.viewAll}>View All</div>
      </div>
      <FrequentlyOrderedPackageList />
    </div>
  )
}
