import React from 'react'
import { Icon } from 'semantic-ui-react'

import style from './CurrentCardPayment.module.css'

const CurrentCardPayment = ({ current, onReset }) => {
  return (
    <div className={style.container}>
      <div className={style.cardDetails}>
        <span>**** **** **** {current.last4}</span>
        <span>
          {current.exp_month}/{current.exp_year}
        </span>
      </div>
      <div className={style.actions}>
        <Icon name="delete" className={style.reset} onClick={onReset} />
      </div>
    </div>
  )
}

export default CurrentCardPayment
