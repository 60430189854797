import React from 'react'
import classnames from 'classnames'
import { NameInitials } from '@labsavvyapp/ui-components'

import style from './style.module.css'

export default function ContactCard({
  contact,
  selected = false,
  unread = 0,
  onClick,
}) {
  return (
    <a
      className={classnames(style.container, { [style.active]: selected })}
      data-test="contact-card"
      onClick={(event) => {
        event.preventDefault()
        onClick(contact)
      }}
    >
      {contact.photo ? (
        <img className={style.photo} src={contact.photo} alt="User" />
      ) : (
        <NameInitials
          firstName={contact.name.first}
          lastName={contact.name.last}
          size={34}
        />
      )}

      <div className={style.containerName}>
        <span className={style.name}>{contact.name.display}</span>
        <p className={style.jobTitle}>{contact.jobTitle}</p>
      </div>

      {(unread && <small className={style.unreadCount}>{unread}</small>) ||
        null}
    </a>
  )
}
