import React from 'react'
import Panel, { PanelBody } from '../../../components/Panel/Panel'
import PreformattedLines from '../../../components/PreformattedLines/PreformattedLines'

import style from './LabResultNotes.module.css'

export default function LabResultNotes({ notes }) {
  return (
    <Panel>
      <PanelBody className={style.container}>
        <PreformattedLines lines={notes} />
      </PanelBody>
    </Panel>
  )
}
