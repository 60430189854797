import React from 'react'
import classnames from 'classnames'
import { format } from 'date-fns'

import style from './Message.module.css'
import { nl2br } from '../utils'

export default function Sender({ message, date }) {
  return (
    <div
      className={classnames([style.container, style.sender])}
      data-test="container-message-bubble"
    >
      <div className={style.messageContainer}>
        <div
          className={style.message}
          // TODO sanitize injected HTML
          dangerouslySetInnerHTML={{ __html: nl2br(message) }}
        />
        <div className={style.date}>
          {date && format(new Date(date), 'HH:mm a')}
        </div>
      </div>
    </div>
  )
}
