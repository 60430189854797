import React, { useEffect, useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { Search, ProfilePhoto, NameInitials } from '@labsavvyapp/ui-components'
import { camelizeKeys } from 'humps'

import style from './ClientSearch.module.css'
import { ListPatients } from '../../../../graphql/clients/queries.js'

export default function ClientSearch({ onSelect, filters = {} }) {
  const { data, loading, refetch } = useQuery(ListPatients, {
    variables: {
      filter: { _id: filters.id },
    },
  })
  const patientData = camelizeKeys(data)

  useEffect(() => {
    if (
      filters.id &&
      patientData &&
      patientData.listPatients.patients.length !== 0 &&
      currentValue === ''
    ) {
      const selectedClient = patientData.listPatients.patients[0]
      setCurrentValue(
        selectedClient.data.name.display ||
          `${selectedClient.data.name.first} ${selectedClient.data.name.last}`,
      )
      onSelect(selectedClient)
    }
  }, [patientData && patientData.listPatients.patients.length])

  const [currentValue, setCurrentValue] = useState('')

  const onSearchChange = (value = '') => {
    if (!value) {
      onSelect()
    }

    if (filters.id && !value) return

    refetch({ filter: { name: value }, page: 1 })
    setCurrentValue(value)
  }

  const onResultSelect = (_, { result }) => {
    onSelect((result && result.data) || {})
  }

  const results = patientData
    ? patientData.listPatients.patients.map(({ id, data }) => ({
        id,
        data: { ...data, id },
        title: '',
        key: id,
      }))
    : []

  return (
    <Search
      className={style.search}
      icon=""
      loading={loading}
      onResultSelect={onResultSelect}
      onSearchChange={onSearchChange}
      placeholder="Search Clients"
      results={results}
      resultRenderer={({ id, data: { name, profilePhoto } }) => (
        <div key={id} className={style.result} data-test="client-name">
          {profilePhoto ? (
            <ProfilePhoto size={30} url={profilePhoto.url} />
          ) : name && name.first && name.last ? (
            <NameInitials
              firstName={name.first}
              lastName={name.last}
              size={30}
            />
          ) : (
            ''
          )}
          <span className={style.name}>{name.display}</span>
        </div>
      )}
      searchText={currentValue}
      selectResultValueMapping="data.name.display"
      showNoResults={false}
    />
  )
}
