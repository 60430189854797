import React from 'react'
import { Image } from 'semantic-ui-react'

import BoardApproved from '../../../assets/board-approved-logo.png'
import BoardApproved2x from '../../../assets/board-approved-logo@2x.png'
import BoardApproved3x from '../../../assets/board-approved-logo@3x.png'

import Info from '../../../assets/info.png'
import Info2x from '../../../assets/info@2x.png'
import Info3x from '../../../assets/info@3x.png'

import style from './ReviewBoardApproved.module.css'

export default function ReviewBoardApproved() {
  return (
    <div className={style.container} data-test="review-board-approved">
      <div className={style.imagesContainer}>
        <Image
          className={style.logo}
          alt="Review Board Approved"
          src={BoardApproved}
          srcSet={`${BoardApproved2x} 2x, ${BoardApproved3x} 3x`}
        />

        <a
          href="https://labsavvy.com/labsavvy-review-board/"
          target="_blank"
          rel="noreferrer noopener"
        >
          <Image
            className={style.infoImage}
            alt="More Info"
            src={Info}
            srcSet={`${Info2x} 2x, ${Info3x} 3x`}
          />
        </a>
      </div>
      <p>
        The content above has been approved by the LabSavvy Review Board. The
        board is made up of top MLS experts from around the united states.
      </p>
    </div>
  )
}
