import React from 'react'
import { Receiver } from '../../components/Messaging/MessageList'
import { camelizeKeys } from 'humps'
import { useQuery } from '@apollo/react-hooks'

import { GetChatUserProfile } from '../../graphql/user/queries'

export default function ReceivedMessage({ contact, message, date }) {
  const { data } = useQuery(GetChatUserProfile, {
    skip: !contact,
    variables: {
      id: contact,
    },
  })
  const contactInfo = camelizeKeys(data?.getChatUserProfile)
  return (
    <Receiver
      name={contactInfo?.name || { first: '', last: '' }}
      photo={contactInfo?.profile_photo?.url || ''}
      message={message}
      date={date}
    />
  )
}
