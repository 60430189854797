import React from 'react'

import style from './PreformattedLines.module.css'

/**
 * Preformatted lines.
 *
 * It gets an array of lines and wraps then in a <pre> tag so you can keep the original text formatting.
 */
export default function PreformattedLines({ lines }) {
  return (
    <div className={style.preWrap}>
      <pre className={style.pre}>{lines.map((line) => `${line}\n`)}</pre>
    </div>
  )
}
