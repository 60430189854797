import React, { useState } from 'react'
import { useHistory, generatePath, Prompt } from 'react-router-dom'
import { Dropdown } from 'semantic-ui-react'
import { Button, useSavingModals } from '@labsavvyapp/ui-components'
import { LAB_REPORTS } from '../../../config/routes'
import { useQuery, useMutation } from '@apollo/react-hooks'
import {
  CreateLabOrder,
  CreateLabOrderProduct,
} from '../../../graphql/lab-orders/mutations'
import { GetPatient } from '../../../graphql/clients/queries.js'
import { GetProvider } from '../../../graphql/providers/queries.js'
import useLocalReducer from '../../../hooks/useLocalReducer'
import style from './ReviewLabOrder.module.css'

const ReviewLabOrder = () => {
  const [submissionType, setSubmissionType] = useState(0)
  const [state, , clearState] = useLocalReducer(
    'new_lab_order',
    (state, action) => ({ ...state, ...action }),
    {},
  )

  const { data: patient } = useQuery(GetPatient, {
    variables: {
      id: state.selectedClient,
    },
  })

  const { data: provider } = useQuery(GetProvider, {
    variables: {
      id: state.selectedProvider,
    },
  })

  const { push } = useHistory()

  const handleSubmissionType = async (_, { value }) => {
    setSubmissionType(value)
  }

  // Form handlers
  const [createLabOrder] = useMutation(CreateLabOrder)
  const [createLabOrderProduct] = useMutation(CreateLabOrderProduct)

  // Progress Modals
  const [modals, { showModals }] = useSavingModals({
    savingMessage: 'Your order is being submitted, please wait...',
    savedMessage: 'Your order has been submitted.',
    callback: async ({ data }) => {
      const { data: createLabOrderResponse } = await createLabOrder({
        variables: { data },
      })

      return createLabOrderResponse
    },
    onError: (error) => `${error}`,
    onSuccess: ({ createLabOrder }) => {
      push(
        generatePath(LAB_REPORTS.view, {
          labReportId: createLabOrder._id,
        }),
      )
    },
  })

  const [
    modalsEmailSubmission,
    { showModals: createViaEmail },
  ] = useSavingModals({
    savingMessage: 'Your order is being submitted, please wait...',
    savedMessage: 'Your order has been submitted.',
    callback: async ({ data }) => {
      const {
        data: createLabOrderProductResponse,
      } = await createLabOrderProduct({
        variables: { data },
      })
      return createLabOrderProductResponse
    },
    onError: (error) => `${error}`,
    onSuccess: () => {
      clearState()
      setTimeout(function () {
        push(LAB_REPORTS.base)
      }, 0)
    },
  })

  const onSubmit = async () => {
    showModals({ data: state.reviewSubmitData })
  }

  const sendByEmail = async () => {
    let data = {}
    data.name = state.orderName
    data.patient_id = state.reviewSubmitData?.patient_id
    data.order_codes = state.reviewSubmitData?.order_items_data?.map(
      (item) => item.order_code,
    )
    createViaEmail({ data })
  }

  return (
    <div className={style.pageContainer}>
      <div className={style.pageTitle}>
        <h2>New Lab Order</h2>
      </div>
      <div className={style.contentContainer}>
        <div className={style.contentHeader}>
          Review the following order before sumbitting.
        </div>
        <div className={style.contentBody}>
          <div className={style.contentBodyLeft}>
            <div className={style.orderInformation}>
              <div className={style.orderInfoListItem}>
                <div>Patient: </div>
                <div>{`${patient?.getPatient?.data?.name?.first || ''} ${
                  patient?.getPatient?.data?.name?.last || ''
                }`}</div>
              </div>
              <div className={style.orderInfoListItem}>
                <div>Order Name: </div>
                <div>{state?.orderName || state?.packageSearch || ''}</div>
              </div>
              <div className={style.orderInfoListItem}>
                <div>Laboratory: </div>
                <div>{provider?.getProvider?.name || ''}</div>
              </div>
              <div className={style.orderInfoListItem}>
                <div>Tests: </div>
                <div>
                  {state?.selectedPanels?.map((item) => (
                    <div key={item.code} className={style.testsListItem}>
                      <div>{item.code} </div>
                      <div>{item.name}</div>
                    </div>
                  ))}
                  {state?.selectedCompendiumPanels?.map((item) => (
                    <div key={item.code} className={style.testsListItem}>
                      <div>{item.code} </div>
                      <div>{item.name}</div>
                    </div>
                  ))}
                </div>
              </div>
              <div className={style.orderInfoListItem}>
                <div>Price: </div>
                <div></div>
              </div>
            </div>
          </div>
          <div className={style.contentBodyRight}>
            <div className={style.orderSubmissionTypeHeader}>
              <div>Choose order type:</div>
              <div>
                <Dropdown
                  selection
                  onChange={handleSubmissionType}
                  placeholder="Choose one the continue"
                  options={[
                    { text: 'Submit directly to lab', value: 1 },
                    { text: 'Send to patient for purchase', value: 2 },
                  ]}
                />
              </div>
            </div>

            {submissionType === 1 && (
              <div className={style.orderSubmissionTypeLab}>
                <p>
                  This order will be sent directly to the lab as a patient hold.
                  The patient will receive an email instruction them with the
                  next steps, and a lab order
                </p>
              </div>
            )}

            {submissionType === 2 && (
              <div className={style.orderSubmissionTypePatientPurchase}>
                <div>Message to Patient:</div>
                <div>
                  <p>Hi {patient?.getPatient?.data?.name?.first}</p>
                  <p>
                    The following lab test package has been sent to you by your
                    provider:
                  </p>
                  <b>{state?.orderName || state?.packageSearch || ''}</b>
                  <p>Tests in this package:</p>
                  <ul>
                    {state?.selectedPanels?.map((item) => (
                      <li key={item.code}>{item.name}</li>
                    ))}
                  </ul>
                  <p>
                    Please follow the directions below to complete the purchase
                    of your lab test package.
                  </p>
                  <p>1. Download and sign up for the LabSavvy mobile app.</p>
                  <p>
                    2. Your order information and purchase link will be found
                    within the Wellness Team tab in the LabSavvy mobile app.
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={style.buttonsContainer}>
        <Button
          className={style.editButton}
          variant="basic"
          onClick={() => push(LAB_REPORTS.new)}
        >
          Edit Order
        </Button>

        <div>
          <Button
            className={style.cancelButton}
            variant="basic"
            onClick={() => push(LAB_REPORTS.base)}
          >
            Cancel
          </Button>
          {submissionType === 1 && (
            <Button
              className={style.submitButton}
              variant="primary"
              onClick={onSubmit}
            >
              Submit Order
            </Button>
          )}
          {submissionType === 2 && (
            <Button
              className={style.submitButton}
              variant="primary"
              onClick={sendByEmail}
            >
              Send order to patient
            </Button>
          )}
        </div>
      </div>
      {/* Modals */}
      {modals}
      {modalsEmailSubmission}
      {/* Unsaved warning */}
      <Prompt
        message={(location) => {
          return location.pathname.startsWith(LAB_REPORTS.new) ||
            location.pathname.match(/lab-reports\/[\s\S]{1,24}\/view/) ||
            !window.sessionStorage.getItem('new_lab_order')
            ? true
            : 'You have unsaved changes! Are you sure you want to leave?'
        }}
      />
    </div>
  )
}

export default ReviewLabOrder
