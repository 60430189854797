import React from 'react'

export default function PDFLink({ url, children, className }) {
  return (
    <a
      href={url}
      target="_blank"
      rel="noreferrer noopener"
      className={className}
    >
      {children}
    </a>
  )
}
