import React from 'react'
import classnames from 'classnames'
import { format } from 'date-fns'
import { Icon } from 'semantic-ui-react'
import { Link, generatePath } from 'react-router-dom'
import get from 'lodash/get'
import { ListRow } from '@labsavvyapp/ui-components'

import style from './LabReportList.module.css'
import { LAB_REPORTS } from '../../../config/routes'

export default function ReportRow({ data }) {
  const { id: labReportId } = data

  return (
    <ListRow
      className={classnames(style.listRow, {
        [style.unreleased]: data.hasUnreleasedResults,
      })}
      as={Link}
      to={{
        pathname: generatePath(LAB_REPORTS.view, { labReportId }),
        state: { prevPath: window.location.pathname },
      }}
      bordered={false}
    >
      <span className={style.reportName} data-test="report-name">
        {data.name}
      </span>
      <span className={style.organization}>{get(data, 'partner.name')}</span>
      <span className={style.user} data-test="report-client">
        {get(data, 'patient.data.name.display')}
      </span>
      <span className={style.ordered}>
        {data.createdAt && format(new Date(data.createdAt), 'MM/dd/yyyy')}
      </span>
      <span className={style.status}>
        {get(data, 'status').replace(/_/g, ' ')}
      </span>
      <span
        className={style.viewed}
        alt={
          data.firstViewed &&
          format(new Date(data && data.firstViewed), 'MM/dd/yyyy')
        }
      >
        <Icon
          name="checkmark"
          className={classnames({
            [style.checkGreen]: data.firstViewed,
            [style.checkGrey]: !data.firstViewed,
          })}
        />
      </span>
    </ListRow>
  )
}
