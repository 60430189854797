import React from 'react'
import classnames from 'classnames'

import style from './LoadingDots.module.css'

export default function LoadingDots({ text = '' }) {
  return (
    <div className={style.loadingDots}>
      <span>{text}</span>&nbsp;
      <div className={classnames(style.dot, style.dotOne)}>.</div>
      <div className={classnames(style.dot, style.dotTwo)}>.</div>
      <div className={classnames(style.dot, style.dotThree)}>.</div>
    </div>
  )
}
