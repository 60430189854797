import React from 'react'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'

import { Button } from '@labsavvyapp/ui-components'

import style from './CardPayment.module.css'

const CARD_OPTIONS = {
  iconStyle: 'solid',
  style: {
    base: {
      iconColor: '#c4f0ff',
      color: '#0a0a0a',
      fontWeight: 500,
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': {
        color: '#fce883',
      },
      '::placeholder': {
        color: '#87bbfd',
      },
    },
    invalid: {
      iconColor: '#ffc7ee',
      color: '#ffc7ee',
    },
  },
}

const CardPayment = ({ onChange }) => {
  const stripe = useStripe()
  const elements = useElements()

  const onSave = async (event) => {
    event.preventDefault()
    const cardElem = elements.getElement(CardElement)
    const { error, token } = await stripe.createToken(cardElem, {
      currency: 'usd',
    })
    if (error)
      // eslint-disable-next-line no-console
      console.error(
        'An error occured trying to create a Token: ',
        error.message,
      )
    else onChange({ token: token.id })
  }

  return (
    <>
      <CardElement options={CARD_OPTIONS} className={style.cardElement} />
      <Button data-test="button-save" disabled={!stripe} onClick={onSave}>
        Save
      </Button>
    </>
  )
}

export default CardPayment
