import React from 'react'
import { Switch, Route } from 'react-router'

import withMainLayout from '../layouts/Main/withMainLayout'
import DashboardScreen from '../pages/Dashboard/DashboardScreen'

export default function DashboardRoutes({ path, me, loggedIn }) {
  return (
    <Route
      path={path}
      render={({ match }) => (
        <Switch>
          <Route
            exact
            path={match.path}
            render={() =>
              withMainLayout(DashboardScreen, {
                me,
                loggedIn,
              })
            }
          />
        </Switch>
      )}
    />
  )
}
