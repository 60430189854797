import gql from 'graphql-tag'

export const SetActivePartner = gql`
  mutation SetActivePartner($partnerId: MongoID!) {
    setActivePartner(partner_id: $partnerId) {
      user {
        partner {
          _id
          name
        }
      }
      access_token
    }
  }
`

export const SetActiveProject = gql`
  mutation SetActiveProject($projectId: MongoID!) {
    setActiveProject(project_id: $projectId) {
      user {
        project {
          _id
        }
      }
      access_token
    }
  }
`
