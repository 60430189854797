import React from 'react'
import { Route, Switch } from 'react-router'

import { PROFILE } from '../config/routes'
import withMainLayout from '../layouts/Main/withMainLayout'
import TabsLayout from '../layouts/TabsLayout/TabsLayout'
import MainLayout from '../layouts/Main/MainLayout'

import AccountVerificationPage from '../pages/Profile/AccountVerificationPage/AccountVerificationPage'
import NewPasswordPage from '../pages/Profile/NewPasswordPage/NewPasswordPage'
import PasswordUpdatedPage from '../pages/Profile/PasswordUpdatedPage/PasswordUpdatedPage'

// Profile Tabs
import ProfileTab from '../pages/Profile/Profile/ProfileTab/ProfileTab'

// Billing Tabs
import ConnectTab from '../pages/Profile/Billing/ConnectTab/ConnectTab'
import InvoicesTab from '../pages/Profile/Billing/InvoicesTab/InvoicesTab'
import HowDoYouPayUsTab from '../pages/Profile/Billing/HowDoYouPayUsTab/HowDoYouPayUsTab'
import HowDoWePayYouTab from '../pages/Profile/Billing/HowDoWePayYouTab/HowDoWePayYouTab'

const getProfileTabItems = ({ me }) => {
  const billingTabMenu = {
    name: 'Billing',
    url: `${PROFILE.billingBase}`,
    tabs: [
      {
        name: 'Connect',
        Content: ConnectTab,
        url: `${PROFILE.billingConnect}`,
      },
    ],
  }

  const connectedAccountTabItems = [
    {
      name: 'How do you pay us?',
      Content: HowDoYouPayUsTab,
      url: `${PROFILE.billingHowDoYouPayUs}`,
    },
    {
      name: 'How do we pay you?',
      Content: HowDoWePayYouTab,
      url: `${PROFILE.billingHowDoWePayYou}`,
    },
    {
      name: 'Invoices',
      Content: InvoicesTab,
      url: `${PROFILE.billingInvoices}`,
    },
  ]

  const items = [
    {
      name: 'Profile',
      url: PROFILE.profileBase,
      tabs: [
        {
          name: 'Profile',
          Content: ProfileTab,
          url: PROFILE.profileBase,
        },
      ],
    },
  ]

  if (me && me.capabilities.isAccountOwner) {
    if (me.partner.has_stripe_account)
      billingTabMenu.tabs = [
        ...billingTabMenu.tabs,
        ...connectedAccountTabItems,
      ]
    items.push(billingTabMenu)
  }

  return items
}

export default function ProfileRoutes({ path, me, loggedIn }) {
  const profileItems = getProfileTabItems({ me })
  return (
    <Route
      path={path}
      render={({ match }) => (
        <Switch>
          <Route
            exact
            path={[
              PROFILE.base,
              PROFILE.profileBase,
              PROFILE.billingBase,
              PROFILE.billingConnect,
              PROFILE.billingHowDoYouPayUs,
              PROFILE.billingHowDoWePayYou,
              PROFILE.billingInvoices,
            ]}
            render={() => (
              <MainLayout loggedIn={loggedIn} me={me}>
                <TabsLayout menuItems={profileItems} />
              </MainLayout>
            )}
          />
          <Route
            exact
            path={`${match.path}/${PROFILE.accountVerification}`}
            render={() =>
              withMainLayout(AccountVerificationPage, {
                me,
                loggedIn,
              })
            }
          />
          <Route
            exact
            path={`${match.path}/${PROFILE.newPassword}`}
            render={() =>
              withMainLayout(NewPasswordPage, {
                me,
                loggedIn,
              })
            }
          />
          <Route
            exact
            path={`${match.path}/${PROFILE.passwordUpdated}`}
            render={() =>
              withMainLayout(PasswordUpdatedPage, {
                me,
                loggedIn,
              })
            }
          />
        </Switch>
      )}
    />
  )
}
