import React from 'react'

import style from './PageError.module.css'
import logo from '../../assets/labsavvy-logo.svg'

export default function PageError({ message, children }) {
  return (
    <div className={style.container}>
      <img className={style.logo} src={logo} alt="labsavvy-logo" />
      <span className={style.message}>{message}</span>
      {children}
    </div>
  )
}
