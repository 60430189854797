import React, { useEffect } from 'react'

import style from './ReportDetails.module.css'
import PDFLink from '../PDFLink'
import LoadingDots from '../LoadingDots/LoadingDots'

const FETCH_ORDER_DOCUMENTS_INTERVAL = 1000 * 60

export const documentTypes = Object.freeze({
  QUEST_AOE: 'AoeUrl',
  REQ_FORM: 'ReqFormPdf',
  ABN_REQ_FORM: 'AbnFormPdf',
  RESULT_DOCUMENT: 'ResultDocument',
})

export default function DocumentDetails({ labOrder, refetch }) {
  const { orderDocuments, resultDocuments, reqFormsLoading } = labOrder
  // If PDF is not available yet, fetch refetch every 20 seconds
  let fetchNewDataInterval
  useEffect(() => {
    if (fetchNewDataInterval) {
      clearInterval(fetchNewDataInterval)
    }

    if (orderDocuments) {
      if (reqFormsLoading) {
        fetchNewDataInterval = setInterval(() => {
          refetch()
        }, FETCH_ORDER_DOCUMENTS_INTERVAL)
      }
    }

    return () => {
      clearInterval(fetchNewDataInterval)
    }
  }, [])

  const getDocumentLink = (document) => {
    const names = {
      [documentTypes.QUEST_AOE]: 'Quest AOE Form',
      [documentTypes.REQ_FORM]: 'Requisition Form',
      [documentTypes.ABN_REQ_FORM]: 'ABN Form',
      [documentTypes.RESULT_DOCUMENT]: 'Results Report',
    }

    if (document.url) {
      return (
        <p key={document.url} className={style.detailsItem}>
          <PDFLink url={document.url}>{names[document.typename]}</PDFLink>
        </p>
      )
    }

    return null
  }

  return (
    <div className={style.documents}>
      {!!orderDocuments.length &&
        orderDocuments.map((document) => getDocumentLink(document))}
      {!!resultDocuments.length &&
        resultDocuments.map((document) =>
          getDocumentLink({
            typename: document.typename,
            url: document.resultsPdfReport.url,
          }),
        )}

      {reqFormsLoading && <LoadingDots text="Loading Order documents" />}
    </div>
  )
}
