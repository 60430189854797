import React from 'react'
import { Icon } from 'semantic-ui-react'
import classnames from 'classnames'

import style from './Breadcrumbs.module.css'

export default function Breadcrumbs({
  text,
  className,
  onClick,
  testId = 'breadcrumbs',
}) {
  const handleClick = (event) => {
    event.preventDefault()
    onClick()
  }

  return (
    <a
      className={classnames(style.breadcrumbs, className)}
      onClick={handleClick}
      data-test={testId}
    >
      <Icon name="caret left" className={style.icon} />
      <span>{text}</span>
    </a>
  )
}
