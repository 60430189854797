import React from 'react'

import Panel, { PanelHeader, PanelBody } from '../../../components/Panel/Panel'
import { SanitizedHTML } from '@labsavvyapp/ui-components'
import style from './TestReferenceFAQ.module.css'

export default function TestReferenceFAQ({ resultSummaries, aka }) {
  const faq = []
  if (resultSummaries.whatIsThisTestUsedFor) {
    faq.push({
      question: 'What is this test used for?',
      answer: resultSummaries.whatIsThisTestUsedFor,
    })
  }

  if (resultSummaries.whatMightAffectTestResults) {
    faq.push({
      question: 'What might affect the test results?',
      answer: resultSummaries.whatMightAffectTestResults,
    })
  }

  if (aka) {
    faq.push({
      question: 'This test is also known as:',
      answer: aka,
    })
  }

  return (
    <Panel>
      {faq.map(({ question, answer }, index) => (
        <React.Fragment key={`faq-${index}`}>
          <PanelHeader>
            <h2>{question}</h2>
          </PanelHeader>

          <PanelBody className={style.answer}>
            <SanitizedHTML text={answer} />
          </PanelBody>
        </React.Fragment>
      ))}
    </Panel>
  )
}
