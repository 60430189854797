import React from 'react'
import { Loader } from 'semantic-ui-react'
import { List, Card } from '@labsavvyapp/ui-components'
import { isEmpty } from '@labsavvyapp/ui-components/lib/utils'

import Row from './Row'
import style from './LabReportsList.module.css'

export default function ClientLabReports({ data = [], loading }) {
  return (
    <div className={style.container}>
      <List className={style.reportList}>
        {loading && (
          <div className={style.containerLoader}>
            <div className={style.wrapperLoader}>
              <Loader active inline="centered" />
            </div>
          </div>
        )}
        {!isEmpty(data) &&
          data.map((report) => <Row key={report.id} data={report} />)}

        {!loading && isEmpty(data) && (
          <Card emptyCard shadowed>
            No lab reports found.
          </Card>
        )}
      </List>
    </div>
  )
}
