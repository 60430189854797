import React from 'react'
import ReactDOM from 'react-dom'

import './styles/style.css'
import App from './App'

import * as serviceWorker from './setupServiceWorker'
serviceWorker.register()

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
)
